import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetUserDocument,
  RemoveUsersFromGroupDocument,
  RemoveProjectsFromUserDocument,
  GetUserQuery,
  User,
  Project,
  UserGroup,
  RemoveUsersFromGroupMutation,
  RemoveProjectsFromUserMutation,
  AssignUserTrackerIdMutation,
  AssignUserTrackerIdDocument,
  InviteUserToTrackerMutation,
  InviteUserToTrackerDocument,
  CreateTrackerContractDocument,
  CreateTrackerContractMutation,
  DeleteTrackerContractDocument,
  DeleteTrackerContractMutation
} from "../../../generated/graphql";
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  Paper,
  ListItemText,
  Backdrop,
  CircularProgress,

} from "@material-ui/core";

import { ButtonPlus, SectionTitle } from "../../shared/Style/Style";
import {
  FlexBetween,
  ListCardClubsGroups,
  PersonalData,
  UserSalaryLink,
  UserSalaryButton,
} from "./UserEdit.style";
import { ProjectModal } from "../Project/ProjectModal";
import { UserEditForm } from "./Fields";
import { USER_ROLE_MAP } from "../../shared/Type/User.type";
import { useStyles } from "../../Login";
import DeleteIcon from "@material-ui/icons/Delete";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { GroupModal } from "../../GroupsModal";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { UserContracts } from "./UserContracts";
import { UserInfoContext } from "../Main";

export const UserEdit = () => {
  const { userId } = useParams<{ userId: string }>();
  const [isProjectModal, setIsProjectModal] = useState<boolean>(false);
  const [isGroupsModal, setGroupsModal] = useState<boolean>(false);
  const [GroupsModalRole, setGroupsModalRole] = useState<string>('');

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [disabledOnLoading, setDisabledOnLoading] = useState(false);
  const [userRoleId, setUserRoleId] = useState(0);

  const isUserCanManagePermissions = useContext(
      UserInfoContext
  )?.userPermissions?.includes("CAN_MANAGE_PERMISSIONS");

  const {
    data,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery<GetUserQuery>(GetUserDocument, {
    variables: {
      userId,
    },
  });

  const [
    removeProjectsFromUser,
    {
      data: removeUsersFromProjectData,
      loading: removeUsersFromProjectLoading,
      error: removeUsersFromProjectError,
    },
  ] = useMutation<RemoveProjectsFromUserMutation>(
    RemoveProjectsFromUserDocument, {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );

  const [
    removeUsersFromGroup,
    {
      data: removeUsersFromGroupData,
      loading: removeUsersFromGroupLoading,
      error: removeUsersFromGroupError,
    },
  ] = useMutation<RemoveUsersFromGroupMutation>(
    RemoveUsersFromGroupDocument,
    {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );

  const [
    inviteUserToTracker,
    {
      data: inviteUserToTrackerData,
      loading: inviteUserToTrackerLoading,
      error: inviteUserToTrackerError,
    },
  ] = useMutation<InviteUserToTrackerMutation>(
    InviteUserToTrackerDocument, {
      variables: { userId },
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );

  const [
    assignTrackerId,
    {
      data: assignTrackerIdData,
      loading: assignTrackerIdLoading,
      error: assignTrackerIdError,
    },
  ] = useMutation<AssignUserTrackerIdMutation>(
    AssignUserTrackerIdDocument, {
      variables: { userId },
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );

  const [
    createTrackerContract,
    {
      data: createTrackerContractData,
      loading: createTrackerContractLoading,
      error: createTrackerContractError
    },
  ] = useMutation<CreateTrackerContractMutation>(
    CreateTrackerContractDocument, {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );

  const [
    deleteTrackerContract,
    {
      data: deleteTrackerContractData,
      loading: deleteTrackerContractLoading,
      error: deleteTrackerContractError
    },
  ] = useMutation<DeleteTrackerContractMutation>(
    DeleteTrackerContractDocument, {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { userId },
        },
      ],
    }
  );


  const classes = useStyles();
  const user = data?.getUser as User;
  const clientRole = data?.getUser?.role?.id === 3;
  const staffRole = data?.getUser?.role?.id !== 3;

  // #region effects

  useEffect(() => {
    if (
      inviteUserToTrackerLoading
      || removeUsersFromGroupLoading
      || removeUsersFromProjectLoading
      || assignTrackerIdLoading
      || createTrackerContractLoading
      || deleteTrackerContractLoading
    ) {
      setDisabledOnLoading(true);
    }
  }, [
    inviteUserToTrackerLoading,
    removeUsersFromGroupLoading,
    removeUsersFromProjectLoading,
    assignTrackerIdLoading,
    createTrackerContractLoading,
    deleteTrackerContractLoading
  ])

  useEffect(() => {
    if (
      inviteUserToTrackerError
      || removeUsersFromGroupError
      || removeUsersFromProjectError
      || assignTrackerIdError
      || createTrackerContractError
      || deleteTrackerContractError
    ) {
      setDisabledOnLoading(false);
      setRequestError(true);
    }
  }, [
    inviteUserToTrackerError,
    removeUsersFromGroupError,
    removeUsersFromProjectError,
    assignTrackerIdError,
    createTrackerContractError,
    deleteTrackerContractError
  ]);

  useEffect(() => {
    if (
      inviteUserToTrackerData
      || removeUsersFromGroupData
      || removeUsersFromProjectData
      || assignTrackerIdData
      || createTrackerContractData
      || deleteTrackerContractData
    ) {
      setDisabledOnLoading(false);
      setRequestError(false);
    }
  }, [
    inviteUserToTrackerData,
    removeUsersFromGroupData,
    removeUsersFromProjectData,
    assignTrackerIdData,
    createTrackerContractData,
    deleteTrackerContractData
  ]);

  useEffect(() => {
    if (inviteUserToTrackerError) {
      setErrorMessage('При приглашении пользователя в трекер произошла ошибка')
    }
  }, [inviteUserToTrackerError]);

  useEffect(() => {
    if (assignTrackerIdError) {
      setErrorMessage('При получении данных трекера пользователя произошла ошибка')
    }
  }, [assignTrackerIdError]);

  useEffect(() => {
    if (removeUsersFromGroupError) {
      setErrorMessage('При удаленеии пользователя из группы произошла ошибка')
    }
  }, [removeUsersFromGroupError]);

  useEffect(() => {
    if (removeUsersFromProjectError) {
      setErrorMessage('При удаленеии пользователя из проекта произошла ошибка')
    }
  }, [removeUsersFromProjectError]);

  useEffect(() => {
    if (createTrackerContractError) {
      setErrorMessage('При создании контракта произошла ошибка')
    }
  }, [createTrackerContractError]);

  useEffect(() => {
    if (deleteTrackerContractError) {
      setErrorMessage('При удаленеии контракта произошла ошибка')
    }
  }, [deleteTrackerContractError]);

  // #endregion


  if (getUserLoading) {
    return (
      <Backdrop className={classes.backdrop} open={getUserLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (getUserLoading) {
    return (
      <Backdrop className={classes.backdrop} open={getUserLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <SectionTitle>
        {clientRole
          ? `Редактирование ${USER_ROLE_MAP.CLIENT.Title}а`
          : `Редактирование ${USER_ROLE_MAP.STAFF.Title}а`
        }
      </SectionTitle>

      <Container maxWidth="lg" style={{ position: 'relative' }}>
        {staffRole && userRoleId !== 3 && (
          <Box display='flex' justifyContent='flex-end'>

            {!!user && !user?.trackerId && (
              <UserSalaryButton
                disabled={disabledOnLoading}
                onClick={() => assignTrackerId({
                  variables: {
                    userId: user?.id
                  }
                })}
              >
                Добавить трекер
              </UserSalaryButton>
            )}

            {!!user && !user?.trackerId && (
              <Box
                mr={2.5}
              >
                <UserSalaryButton
                  disabled={disabledOnLoading}
                  onClick={() => inviteUserToTracker({
                    variables: {
                      userId: user?.id
                    }
                  })}
                >
                  Пригласить в трекер
                </UserSalaryButton>
              </Box>
            )}

            <UserSalaryButton>
              <UserSalaryLink
                to={`/user/${userId}/salaryHistory`}
              >
                история зарплат
              </UserSalaryLink>
            </UserSalaryButton>

            {isUserCanManagePermissions && (

                <UserSalaryButton>
              <UserSalaryLink
                  to={`/user/${userId}/permissions`}
              >
                Разрешения пользователя
              </UserSalaryLink>
            </UserSalaryButton>)}

            <UserSalaryButton
              disabled={!user?.salary}
            >
              <UserSalaryLink
                to={`/user/${userId}/salary`}
              >
                зп ведомость
              </UserSalaryLink>
            </UserSalaryButton>
          </Box>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: "16px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    component={PersonalData}
                    variant="subtitle1"
                    gutterBottom
                  >
                    Персональные данные
                  </Typography>

                  <UserEditForm user={user} setUserRoleId={setUserRoleId}/>
                </Grid>

                <Grid item xs={12} md={6} style={{ marginBottom: '75px' }}>
                  <FlexBetween>
                    <Box>
                      <Grid container>
                        <Typography
                          component={PersonalData}
                          variant="subtitle1"
                          gutterBottom
                        >
                          Проекты
                        </Typography>
                        <ButtonPlus
                          width={250}
                          variant="contained"
                          onClick={() => setIsProjectModal(true)}
                        >
                          Добавить проект
                        </ButtonPlus>
                      </Grid>

                      <ListCardClubsGroups>
                        <List
                          component="nav"
                          aria-label="secondary mailbox folders"
                        >
                          {user?.projects?.map((project: Project | null) => (
                            <Grid
                              item
                              md={4}
                              xs={6}
                              key={project?.id}
                            >
                              <ListItem
                                button
                                style={{ borderBottom: "1px solid #eee", marginBottom: "0.5rem" }}
                              >
                                <ListItemText primary={`${project?.name}`} />
                                <IconButton
                                  disabled={disabledOnLoading}
                                  color="secondary"
                                  aria-label="delete"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeProjectsFromUser({
                                      variables: {
                                        userId: userId,
                                        projectIds: [project?.id],
                                      },
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize={"small"} />
                                </IconButton>
                              </ListItem>
                            </Grid>
                          ))}

                          {(getUserError || removeUsersFromProjectError) && (
                            <ErrorSnackbar
                              error={
                                getUserError || removeUsersFromProjectError
                              }
                            />
                          )}
                        </List>
                      </ListCardClubsGroups>
                    </Box>
                  </FlexBetween>

                  {isProjectModal && (
                    <ProjectModal
                      setIsProjectModal={setIsProjectModal}
                      open={isProjectModal}
                      user={user}
                    />
                  )}

                  {!clientRole && userRoleId !== 3 && (
                    <>
                      <FlexBetween style={{ marginTop: '20px' }}>
                        <Box>
                          <Grid container>
                            <Typography
                              component={PersonalData}
                              variant="subtitle1"
                              gutterBottom
                            >
                              Группы
                            </Typography>
                            <ButtonPlus
                              width={250}
                              variant="contained"
                              onClick={() => {
                                setGroupsModalRole('add');
                                setGroupsModal(true);
                              }}
                            >
                              Добавить группу
                            </ButtonPlus>
                          </Grid>

                          <ListCardClubsGroups>
                            <List
                              component="nav"
                              aria-label="secondary mailbox folders"
                            >
                              {user?.groups?.map((group: UserGroup | null) => (
                                <Grid
                                  item
                                  md={4}
                                  xs={6}
                                  key={group?.id}
                                >
                                  <ListItem
                                    button
                                    style={{ borderBottom: "1px solid #eee", marginBottom: "0.5rem" }}
                                  >
                                    <ListItemText primary={`${group?.name}`} />
                                    <IconButton
                                      disabled={disabledOnLoading}
                                      color="secondary"
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeUsersFromGroup({
                                          variables: {
                                            userIds: [userId],
                                            userGroupId: group?.id,
                                          },
                                        });
                                      }}
                                    >
                                      <DeleteIcon fontSize={"small"} />
                                    </IconButton>
                                  </ListItem>
                                </Grid>
                              ))}

                              {(getUserError || removeUsersFromGroupError) && (
                                <ErrorSnackbar
                                  error={
                                    getUserError || removeUsersFromGroupError
                                  }
                                />
                              )}
                            </List>
                          </ListCardClubsGroups>
                        </Box>
                      </FlexBetween>

                      <>
                        {isGroupsModal && (
                          <GroupModal
                            role={GroupsModalRole}
                            setGroupsModalRole={setGroupsModalRole}
                            setGroupsModal={setGroupsModal}
                            open={isGroupsModal}
                            user={user}
                          />
                        )}
                      </>

                      <UserContracts
                        disabledOnLoading={disabledOnLoading}
                        user={user}
                        createContract={createTrackerContract}
                        deleteContract={deleteTrackerContract}
                      />

                      <ErrorMessageSnackbar
                        open={requestError}
                        closeHandler={() => setRequestError(false)}
                        message={errorMessage}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
