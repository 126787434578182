import { gql } from "@apollo/client";

export const LOGIN_FROM_ADMIN = gql`
  mutation loginFromAdmin($email: String!, $password: String!) {
    loginFromAdmin(email: $email, password: $password) {
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String,! $isFrom: isFromEnum!) {
    forgotPassword(email: $email, isFrom: $isFrom)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!, $isFrom: isFromEnum!) {
    resetPassword(password: $password, token: $token, isFrom: $isFrom) {
      token
    }
  }
`;

export const CONFIRM_USER = gql`
  mutation confirmUser($token: String!) {
    confirmUser(token: $token)
  }
`;
