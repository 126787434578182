import { Container } from "@material-ui/core";
import { Header } from "../../shared/Style/Style";
import { Job } from "./Job";
import { Agreement } from "./Agreement";
import { Department } from "./Department";
import { SettingsInner } from "./Settings.style";
import { ProjectType } from "./ProjectType";
import { BaseTaskStatus } from "./BaseTaskStatus";
import { ThemeBackground } from "./ThemeBackground";
import { ManualDiscounts } from "./ManualDiscounts";
import { QuotesCollection } from "./QuotesCollection/QuotesCollection";

export const Settings = () => {
  return (
    <Container maxWidth="lg">
      <Header>Налаштування</Header>
      <SettingsInner>
        <ManualDiscounts />
        <Job />
        <Agreement />
        <Department />
        <ProjectType />
        <BaseTaskStatus />
        <ThemeBackground />
        <QuotesCollection />
      </SettingsInner>
    </Container>
  );
};
