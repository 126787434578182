import {
  GetUsersForSchoolDocument,
  GetUsersForSchoolQuery,
  UserRoleEnum,
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { Autocomplete } from "@material-ui/lab";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { StyledTextField } from "../Style";
import { QUERY_LIMIT } from "../constants";
import { useMemo, useState } from "react";
import { useDebounce } from "../../shared/Utils/OtherOperations/useDebounce";
import { useInfiniteScrollForQuery } from "../hooks";

interface SelectManagerProps {
  managerId: string;
  inputError: boolean;
  setManagerId: (managerId: string) => void;
}

export const SelectManager = ({
  managerId,
  inputError,
  setManagerId,
}: SelectManagerProps) => {
  const [searchKey, setSearchKey] = useState("");
  const debouncedValue = useDebounce(searchKey, 500);

  const variables = useMemo(
    () => ({
      skip: 0,
      roles: [UserRoleEnum.Super, UserRoleEnum.Manager],
      statuses:
        process.env.REACT_APP_ENV === "production" ? ["VERIFIED"] : undefined,
      searchKey: debouncedValue,
    }),
    [debouncedValue]
  );

  const {
    items: users,
    loading,
    error,
    handleScroll,
  } = useInfiniteScrollForQuery<GetUsersForSchoolQuery>({
    query: GetUsersForSchoolDocument,
    variables,
    key: "getUsersData",
    getItems: (data) => data.getUsers?.users || [],
    limitKey: "limit",
    limit: QUERY_LIMIT,
  });

  const handleSetManager = (managerId: string) => {
    const currentManagerId = users.find(({ id }) => id === managerId)?.id;
    if (managerId) {
      setSearchKey("");
    }
    setManagerId(currentManagerId);
  };

  const { requestError, setRequestError, errorMessage } = useRequestError(
    error,
    "При загрузке списка пользователей произошла ошибка"
  );
  const getUserFullName = (managerId: string) => {
    if (!managerId) return "";
    const user = users.find((users) => users.id === managerId);
    return `${user?.firstName} ${user?.lastName}`;
  };

  return (
    <>
      <Autocomplete
        options={users.map((user) => user.id)}
        getOptionLabel={(option) => {
          const user = users.find((user) => user.id === option);
          return `${user?.firstName} ${user?.lastName}`;
        }}
        inputValue={
          searchKey.length > 0 ? searchKey : getUserFullName(managerId)
        }
        onChange={(_, value) => handleSetManager(value)}
        value={users.find((user) => user.id === managerId)?.id || null}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        renderInput={(params) => (
          <>
            <StyledTextField
              label="Менеджер"
              variant="filled"
              error={inputError}
              onChange={({ target: { value: inputValue } }) => {
                if (managerId) {
                  return setSearchKey("");
                }
                setManagerId("");
                setSearchKey(inputValue);
              }}
              {...params}
            />
            {loading && <LoadingProgress />}
          </>
        )}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
