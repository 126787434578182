import React from "react";
import {
  SearchBar,
  SearchBarInput,
} from "../../../PeopleSearchBar/PeopleSearchBar.style";
import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme } from "../../../shared/Style/Style";

interface BooksSearchBarProps {
  label: string;
  activeFilter: string;
  setActiveFilter(state: string): void;
}

export const BooksSearchBar = ({
  label,
  activeFilter,
  setActiveFilter,
}: BooksSearchBarProps) => {
  return (
    <SearchBar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <SearchBarInput
          label={label}
          value={activeFilter}
          onChange={({ target: { value } }) => setActiveFilter(value)}
        />
      </ThemeProvider>
    </SearchBar>
  );
};
