import { SubscriptionContainer } from "../Style";
import { Subtitle } from "../../shared/Style/Style";
import { CreateSchoolPupilsSubscription } from "./CreateSchoolPupilsSubscription";
import { SchoolPupilsPayments } from "./SchoolPupilsPayments";

export const SchoolPupilsSubscription = () => {
  return (
    <SubscriptionContainer>
      <Subtitle>
        Активація підписок учням через приналежність до простору
      </Subtitle>
      <CreateSchoolPupilsSubscription />
      <SchoolPupilsPayments />
    </SubscriptionContainer>
  );
};
