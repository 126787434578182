export const textColors = {
  white: "#FFFFFF",
  black: "#000000",
};

export type TextColorType = keyof typeof textColors;

export const initialFieldsState = {
  name: {
    inputName: "name",
    label: "Название коллекции",
    value: "" as string,
    required: true,
  },
  slug: {
    inputName: "slug",
    label: "Слаг",
    value: "" as string,
    required: true,
  },
  description: {
    inputName: "description",
    label: "Описание",
    value: "" as string,
    required: false,
  },
  accentColor: {
    inputName: "accentColor",
    label: "Акцентные кнопки",
    value: "#FCB231" as string,
    required: true,
  },
  textColor: {
    inputName: "textColor",
    label: "Цвет текста в кнопке",
    value: textColors.white as TextColorType,
    required: true,
  },
  welcomeHtml: {
    inputName: "welcomeHtml",
    label: "WelcomeHtml",
    value: "" as string,
    required: true,
  },
  schoolLogo: {
    inputName: "schoolLogo",
    label: "Лого школы",
    value: null as null | File | string,
    required: true,
  },
  schoolSystemLogoShort: {
    inputName: "schoolSystemLogoShort",
    label: "Лого системы короткое",
    value: null as null | File | string,
    required: true,
  },
  schoolSystemLogoFull: {
    inputName: "schoolSystemLogoFull",
    label: "Лого системы полное",
    value: null as null | File | string,
    required: true,
  },
  banner: {
    inputName: "banner",
    label: "Баннер",
    value: null as null | File | string,
    required: true,
  },
  image: {
    value: null as null | File | string,
    required: false,
  },
} as const;

export type FieldsStateType = typeof initialFieldsState;
