import { FC } from "react";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import { LoadingProgressOverlay } from "../../../shared/LoadingProgress";
import { NotificationsType } from "./QuotesCollection";
import { GetQuoteCollectionsDocument, useDeleteQuoteCollectionMutation } from "../../../../generated/graphql";

interface DeleteQuotesCollectionProps {
    id: string;
    setNotifications(notifications: NotificationsType): void;
}

export const DeleteQuotesCollection: FC<DeleteQuotesCollectionProps> = ({
    id,
    setNotifications
}) => {
    const [deleteQuoteCollection, { loading: deleteQuoteLoading }] = useDeleteQuoteCollectionMutation({
        variables: { deleteQuoteCollectionId: id },
        onCompleted: () => {
            setNotifications({ success: true, message: "Колекцію цитат успішно видалено" });
        },
        onError: () => {
            setNotifications({ error: true, message: "Сталася помилка при видаленні колекції цитат" });
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GetQuoteCollectionsDocument }],
    });

    if (deleteQuoteLoading) {
        return <LoadingProgressOverlay />;
    }

    return (
        <IconButton
            onClick={() => deleteQuoteCollection()}
        >
            <Delete color="secondary" />
        </IconButton>
    );
};
