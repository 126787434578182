import { styled } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core";

export const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "20px",
    borderRadius: "10px",
    minWidth: "400px",
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: "bold",
  paddingBottom: "10px",
});
