import { FC, useEffect, useRef, useState } from "react";
import {Dialog} from "@material-ui/core";
import { LoadingProgressOverlay } from "../../../shared/LoadingProgress";
import {
    ButtonCustom,
    ButtonsWrapper,
    CancelButton,
    ModalInput,
    ImportFileUpload,
} from "../../../shared/Style/Style";
import { ImportQuotesModalContainer } from "../Settings.style";
import { NotificationsType } from "./QuotesCollection";
import {
    GetQuoteCollectionsDocument,
    useCreateQuoteCollectionMutation,
    useUpdateQuoteCollectionMutation
} from "../../../../generated/graphql";

export const initialFileData: {
  file: File | null;
  name: string;
} = {
  file: null,
  name: "Вкажіть ім'я колекції",
};

interface ImportQuotesModalProps {
    quotesId?: string;
    quotesName?: string;
    closeModal(): void;
    setNotifications(notifications: NotificationsType): void;
}

export const ImportQuotesModal: FC<ImportQuotesModalProps> = ({ quotesId, quotesName, closeModal, setNotifications }) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [currentFile, setCurrentFile] = useState(initialFileData);

    useEffect(() => {
        if (quotesId && quotesName && currentFile.file === null) {
            setCurrentFile({ ...currentFile, name: quotesName });
        }
    }, []);

    const [createQuoteCollection, { loading: createQuoteLoading  }] = useCreateQuoteCollectionMutation({
        variables: {
            name: currentFile.name,
            file: currentFile.file,
        },
        onCompleted: (data) => {
            if (data.createQuoteCollection?.id) {
                setNotifications({ success: true, message: "Колекцію цитат успішно завантажено" });
            } else {
                setNotifications({ error: true, message: "Сталася помилка при завантаженні колекції цитат"})
            }
        },
        onError: () => {
            setNotifications({ error: true, message: "Сталася помилка при завантаженні колекції цитат"})
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GetQuoteCollectionsDocument }],
    });

    const [updateQuoteCollection, { loading: updateQuoteLoading }] = useUpdateQuoteCollectionMutation({
        variables: {
            updateQuoteCollectionId: quotesId,
            name: currentFile.name,
            file: currentFile.file,
        },
        onCompleted: () => {
            setNotifications({ success: true, message: "Колекцію цитат успішно оновлено" });
        },
        onError: () => {
            setNotifications({ error: true, message: "Сталася помилка при оновленні колекції цитат"})
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GetQuoteCollectionsDocument }],
    });

    const loading = createQuoteLoading || updateQuoteLoading;

    const handleDrop = (file: File) => {
        if (file.type === "text/csv") {
            setCurrentFile({ ...currentFile, file});
        } else {
            setNotifications({ error: true, message: "Неправильний формат файла, для завантаження використовуйте .csv"})
        };
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (quotesId && quotesName) {
            await updateQuoteCollection() 
        } else { await createQuoteCollection() }
        closeModal();
    };

    return (
        <Dialog open>
            <ImportQuotesModalContainer>
                {loading && <LoadingProgressOverlay />}
                
                <form onSubmit={handleSubmit}>
                    <ButtonsWrapper>
                        <ModalInput
                            variant="filled"
                            type="text"
                            autoFocus
                            placeholder={currentFile.name}
                            defaultValue={quotesName}
                            onChange={(e) => setCurrentFile({ ...currentFile, name: e.target.value.trim() })}
                        />
                        <ButtonCustom onClick={() => fileInput.current.click()}>
                        <span>Обрати файл</span>
                        <ImportFileUpload
                            type="file"
                            accept="text/csv"
                            ref={fileInput}
                            onChange={({ target: { files } }) => {
                                if (files && files[0]) handleDrop(files[0]);
                            }}
                        />
                        </ButtonCustom>
                    </ButtonsWrapper>

                    <ButtonsWrapper>
                        <CancelButton onClick={() => closeModal()}>Скасувати</CancelButton>
                        <ButtonCustom
                            type="submit"
                            disabled={currentFile.file === null || currentFile.name === initialFileData.name || !currentFile.name}
                        >
                            Завантажити
                        </ButtonCustom>
                    </ButtonsWrapper>
                </form>
            </ImportQuotesModalContainer>
        </Dialog>
    );
};
