import { useEffect, useRef, useState } from "react";
import { useInviteUsersByListV3Mutation } from "../../../generated/graphql";
import { Dialog, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGetValidatedUserInvitationsList } from "./hooks";
import { setErrorMessage } from "../utils";
import { initialFileData, initialUploadStatus } from "../constants";
import { ImportModalContainer } from "../Style";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalInput,
  ImportFileUpload,
} from "../../shared/Style/Style";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { LoadingProgressOverlay } from "../../shared/LoadingProgress";

interface MobileAppModalProps {
  open: boolean;
  close(): void;
  successNotification(): void;
  schoolId: string;
}

export const ImportNotificationModal = ({
  open,
  close,
  successNotification,
  schoolId,
}: MobileAppModalProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [currentFile, setCurrentFile] = useState(initialFileData);
  const [uploadStatus, setUploadStatus] = useState(initialUploadStatus);

  const {
    validateUserInvitationsList,
    validateUserListData,
    validateUserListLoading,
    validateUserListError,
  } = useGetValidatedUserInvitationsList();

  const [
    inviteUsersByListV3,
    { loading: inviteLoading, error: inviteError, data: inviteData },
  ] = useInviteUsersByListV3Mutation();

  const validationResult =
    validateUserListData?.validateUserInvitationsList || null;
  const inviteUsersResult = inviteData?.inviteUsersByListV3 || null;
  const isLoading = validateUserListLoading || inviteLoading;
  const isCurrentFileEmpty = currentFile === initialFileData;
  const isSubmitDisabled =
    isCurrentFileEmpty || validationResult?.success === false;

  useEffect(() => {
    if (currentFile !== initialFileData) {
      validateUserInvitationsList({ variables: { list: currentFile.file } });
    }
  }, [currentFile]);

  useEffect(() => {
    if (validationResult && !validateUserListLoading) {
      const { success, errors } = validationResult;
      if (success) {
        setUploadStatus({
          status: true,
          message: "Файл готовий до відправлення",
          variant: "success",
        });
      }
      if (!success && errors) {
        setUploadStatus({
          status: true,
          message:
            "Виправте та повторно завантажте файл: " + setErrorMessage(errors),
          variant: "error",
        });
      }
    }

    if (validateUserListError) {
      setUploadStatus({
        status: true,
        message:
          "Помилка при перевірці файлу: " + validateUserListError.message,
        variant: "error",
      });
    }
  }, [validateUserListError, validateUserListLoading, validationResult]);

  useEffect(() => {
    if (inviteUsersResult?.length === 0) {
      close();
      successNotification();
    }

    if (inviteUsersResult?.length > 0) {
      setUploadStatus({
        status: true,
        message: setErrorMessage(inviteUsersResult),
        variant: "info",
      });
    }

    if (inviteError) {
      setUploadStatus({
        status: true,
        message: "Помилка відправлення: " + inviteError.message,
        variant: "error",
      });
    }
  }, [inviteUsersResult, inviteError]);

  const handleDrop = (file: File) => {
    if (file.type === "text/csv") {
      setCurrentFile({ file, name: file.name });
    } else {
      setUploadStatus({
        status: true,
        message: "Файл має бути у форматі .csv",
        variant: "error",
      });
    }

    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const handleCancel = () => {
    if (isCurrentFileEmpty) close();
    setCurrentFile(initialFileData);
  };

  const handleSubmit = async () => {
    try {
      await inviteUsersByListV3({
        variables: { list: currentFile.file, schoolId },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Dialog open={open}>
        <ImportModalContainer>
          {isLoading && <LoadingProgressOverlay />}

          <CloseIconButton close={close} />

          <ButtonsWrapper>
            <ModalInput
              variant="filled"
              type="text"
              placeholder={currentFile.name}
              disabled
            />
            <ButtonCustom onClick={() => fileInput.current?.click()}>
              <span>Вибрати</span>
              <ImportFileUpload
                type="file"
                accept="text/csv"
                ref={fileInput}
                onChange={({ target: { files } }) => {
                  files?.[0] && handleDrop(files[0]);
                }}
              />
            </ButtonCustom>
          </ButtonsWrapper>

          <ButtonsWrapper>
            <CancelButton onClick={handleCancel}>Скасувати</CancelButton>

            <ButtonCustom disabled={isSubmitDisabled} onClick={handleSubmit}>
              Відправити
            </ButtonCustom>
          </ButtonsWrapper>
        </ImportModalContainer>
      </Dialog>

      <Snackbar
        open={uploadStatus.status}
        onClose={() => setUploadStatus(initialUploadStatus)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={uploadStatus.variant}
          onClose={() => setUploadStatus(initialUploadStatus)}
        >
          {uploadStatus.message
            .split("\n")
            .map((item, i) => <div key={i}>{item}</div>) || "Невідома помилка"}
        </Alert>
      </Snackbar>
    </>
  );
};
