import React from "react";
import {
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import {
    AddByPersonalSchoolIdPermissionDocument,
    AddByPersonalSchoolIdPermissionMutation,
    GetUserFeatureFlagsBySchoolIdDocument,
    GetUserFeatureFlagsBySchoolIdQuery,
    RemoveByPersonalSchoolIdPermissionDocument,
    RemoveByPersonalSchoolIdPermissionMutation,
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";

// Типы данных
interface UserFeatureFlag {
    id: string;
    name: string;
    flag: string;
    isGranted: boolean;
}

interface PermissionsSettingsProps {
    schoolId: string;
}

export const UserFeatureFlags: React.FC<PermissionsSettingsProps> = ({ schoolId }) => {
    // Получение списка флагов функций
    const {
        data,
        loading: flagsLoading,
        error: flagsError,
        refetch,
    } = useQuery<GetUserFeatureFlagsBySchoolIdQuery>(GetUserFeatureFlagsBySchoolIdDocument, {
        variables: { schoolId },
        fetchPolicy: "network-only",
    });

    // Мутации для добавления и удаления разрешений с новыми резолверами
    const [addPermission, { loading: addPermissionLoading, error: addPermissionError }] =
        useMutation<AddByPersonalSchoolIdPermissionMutation>(AddByPersonalSchoolIdPermissionDocument);

    const [removePermission, { loading: removePermissionLoading, error: removePermissionError }] =
        useMutation<RemoveByPersonalSchoolIdPermissionMutation>(
            RemoveByPersonalSchoolIdPermissionDocument
        );

    // Обработка ошибок
    const error = flagsError || addPermissionError || removePermissionError;
    const { requestError, setRequestError } = useRequestError(error);

    const isLoading = flagsLoading || addPermissionLoading || removePermissionLoading;

    // Обработчик изменения статуса чекбокса с новыми параметрами
    const handlePermissionChange = async (flagId: string, isGranted: boolean) => {
        try {
            if (isGranted) {
                await addPermission({
                    variables: {
                        schoolId,
                        flagId, // Теперь используем flagId вместо flag
                    },
                });
            } else {
                await removePermission({
                    variables: {
                        schoolId,
                        flagId, // Теперь используем flagId вместо flag
                    },
                });
            }
            // Обновляем данные после успешной мутации
            refetch();
        } catch (e) {
            console.error("Ошибка при изменении разрешения:", e);
            setRequestError(true);
        }
    };

    // Сообщение об ошибке в зависимости от типа ошибки
    const errorMessage =
        (flagsError && "При загрузке списка разрешений произошла ошибка") ||
        (addPermissionError && "При добавлении разрешения произошла ошибка") ||
        (removePermissionError && "При удалении разрешения произошла ошибка");

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    const featureFlags = data?.getUserFeatureFlagsBySchoolId || [];

    return (
        <Box mt={2}>
            <Typography variant="h6" gutterBottom>
                Настройки доступов
            </Typography>

            {featureFlags.length === 0 ? (
                <Typography color="textSecondary">Доступных флагов функций не найдено</Typography>
            ) : (
                <FormGroup>
                    {featureFlags.map((featureFlag) => (
                        <FormControlLabel
                            key={featureFlag.id}
                            control={
                                <Checkbox
                                    checked={featureFlag.isGranted}
                                    onChange={(e) => handlePermissionChange(featureFlag.id, e.target.checked)}
                                    color="primary"
                                    disabled={isLoading}
                                />
                            }
                            label={featureFlag.name || featureFlag.flag}
                        />
                    ))}
                </FormGroup>
            )}

            {requestError && (
                <Typography color="error" variant="body2" gutterBottom>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
};
