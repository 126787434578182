import styled from "styled-components";
import { Tab } from "@material-ui/core";
import { black, primary, secondary } from "../shared/Style/Style";

export const CustomTab = styled(Tab)<{
  $active: boolean;
  $disabled?: boolean;
}>`
  &.MuiTab-root {
    background-color: ${({ $active }) =>
      $active ? `${primary}` : `${secondary}`};
    color: ${black};
    box-shadow: none;
    min-width: 0;
    min-height: 36px;
    margin-right: 10px;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  }
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const TabIndicatorProps = {
  style: {
    display: "none",
  },
};
