import { FC, useRef, useState } from "react";
import { ButtonCustom, StyledFilterSelectList } from "../Style/Style";
import { Box, FormControl, InputLabel, TextField } from "@material-ui/core";
import styled from "styled-components";

interface FilterRangeInputProps {
  keyValue: string;
  title: string;
  minInputLabel?: string;
  maxInputLabel?: string;
  minInputPlaceholder?: string;
  maxInputPlaceholder?: string;
  displayInputValueInTitle?: boolean;
  handleApplyFilter: (values: { [key: string]: number }) => void;
  minInputMaxLength?: number;
  maxInputMaxLength?: number;
  defaultMinValue?: number;
  defaultMaxValue?: number;
  showButton?: boolean;
  disabled?: boolean;
}

export const FilterRangeInput: FC<FilterRangeInputProps> = ({
  keyValue,
  title,
  minInputLabel = "Мин. кол-во",
  maxInputLabel = "Макс. кол-во",
  minInputPlaceholder,
  maxInputPlaceholder,
  handleApplyFilter,
  displayInputValueInTitle,
  minInputMaxLength = 9,
  maxInputMaxLength = 9,
  defaultMinValue,
  defaultMaxValue,
  showButton = false,
  disabled,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [minValue, setMinValue] = useState<number | undefined>(defaultMinValue);
  const [maxValue, setMaxValue] = useState<number | undefined>(defaultMaxValue);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newMinValue = formData.get("minValue")
      ? Number(formData.get("minValue"))
      : undefined;
    const newMaxValue = formData.get("maxValue")
      ? Number(formData.get("maxValue"))
      : undefined;

    setMinValue(newMinValue);
    setMaxValue(newMaxValue);

    handleApplyFilter({
      [`min${keyValue}`]: newMinValue,
      [`max${keyValue}`]: newMaxValue,
    });
  };

  return (
    <StyledFilterInputFormControl>
      <StyledFilterSelectList
        value
        renderValue={(v) => {
          if (displayInputValueInTitle && (minValue || maxValue)) {
            const min = minValue || 0;
            const max = maxValue || 9999;
            return <span>{`${title}: ${min} - ${max}`}</span>;
          }

          return <span>{title}</span>;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
      >
        <form ref={formRef} onSubmit={handleSubmit}>
          <StyledTextFieldContainer
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <InputLabel>{minInputLabel}:</InputLabel>
            <TextField
              type="number"
              name="minValue"
              defaultValue={defaultMinValue || ""}
              placeholder={minInputPlaceholder}
              inputProps={{ min: 0, maxLength: minInputMaxLength }}
              disabled={disabled}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <InputLabel>{maxInputLabel}:</InputLabel>
            <TextField
              type="number"
              name="maxValue"
              defaultValue={defaultMaxValue || ""}
              placeholder={maxInputPlaceholder}
              inputProps={{ min: 0, maxLength: maxInputMaxLength }}
              disabled={disabled}
            />
          </StyledTextFieldContainer>
          {showButton && (
            <Box p="10px 20px">
              <ButtonCustom type="submit" disabled={disabled} width="100px">
                Применить
              </ButtonCustom>
            </Box>
          )}
        </form>
      </StyledFilterSelectList>
    </StyledFilterInputFormControl>
  );
};

export const StyledFilterInputFormControl = styled(FormControl)`
  border-radius: 2px;
  background-color: #b3b3b3;
  width: 300px;
  height: 35px;
  margin-left: 20px;
  & .MuiInput-underline:before,
  & .MuiInput-underline:after {
    all: unset;
    &:focus,
    &:active,
    &:hover {
      all: unset;
    }
  }
`;

const StyledTextFieldContainer = styled.div`
  display: flex;
  width: 300px;
  padding: 10px 20px;

  label {
    margin-right: 15px;
  }
`;
