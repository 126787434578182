import React, { useCallback, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField } from "../Style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { useGetWhiteLabelCollectionsForSchoolQuery } from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";

interface SelectWhiteLabelCollectionsProps {
  collectionId: string;
  setCollectionId(id: string): void;
}

export const SelectWhiteLabelCollections = ({
  setCollectionId,
  collectionId,
}: SelectWhiteLabelCollectionsProps) => {
  const { data, loading, error } = useGetWhiteLabelCollectionsForSchoolQuery({
    variables: {
      filters: {
        isPublished: true,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const { requestError, setRequestError } = useRequestError(error);

  const whiteLabelCollections = data?.getWhiteLabelCollections ?? [];

  const findCollectionById = useCallback(
    (id: string | null) =>
      whiteLabelCollections.find((collection) => collection.id === id) || null,
    [whiteLabelCollections]
  );

  const handleCollectionChange = useCallback(
    (_, newValue) => setCollectionId(newValue || null),
    [setCollectionId]
  );

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  return (
    <>
      <Autocomplete
        options={whiteLabelCollections.map(({ id }) => id)}
        getOptionLabel={(option) => findCollectionById(option)?.name || null}
        onChange={handleCollectionChange}
        value={findCollectionById(collectionId)?.id || null}
        disabled={!whiteLabelCollections.length}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label="Стиль"
            variant="filled"
            error={!!requestError}
          />
        )}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message="При загрузке списка стилей произошла ошибка"
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
