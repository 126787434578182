import { FC, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Save from "@material-ui/icons/Save";
import { LoadingProgressOverlay } from "../../../shared/LoadingProgress";
import { NotificationsType } from "./QuotesCollection";
import { API } from "../../../../core/axios/client";
import { downloadFile } from "../../../../helpers/downloadFile.helper";

interface DownloadQuotesCollectionProps {
    collectionId: string;
    collectionName: string;
    setNotifications(notifications: NotificationsType): void;
}

export const DownloadQuotesCollection: FC<DownloadQuotesCollectionProps> = ({
    collectionId,
    collectionName,
    setNotifications,
}) => {
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const downloadQuotesCollectionFile = async () => {
        setDownloadInProgress(true);
        try {
            const response = await API.get(`/quotes/${collectionId}/export`, {
                responseType: 'blob',
            });

            downloadFile(response, `${collectionName}.csv`);
        } catch (error) {
            console.error(error);
            setNotifications({ error: true, message: "При завантаженні колекції цитат виникла помилка" });
        } finally {
            setDownloadInProgress(false);
        }
    };

    if (downloadInProgress) {
        return <LoadingProgressOverlay />;
    }
    
    return (
        <Tooltip title="Завантажити колекцію цитат">
            <IconButton onClick={downloadQuotesCollectionFile}>
                <Save />
            </IconButton>
        </Tooltip>
    );
};
