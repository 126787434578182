import { Suspense, lazy, useState } from "react";
import { LoadingProgressOverlay } from "../../shared/LoadingProgress";
import { initialFieldsState } from "../components/WhiteLabelCollection/constants";

const CreateCollection = lazy(
  () => import("../components/WhiteLabelCollection/WhiteLabelCollection")
);

const CreateCollectionLazy = () => {
  const [collectionFields, setCollectionFields] = useState(initialFieldsState);

  return (
    <Suspense fallback={<LoadingProgressOverlay />}>
      <CreateCollection
        collectionFields={collectionFields}
        setCollectionFields={setCollectionFields}
      />
    </Suspense>
  );
};

export default CreateCollectionLazy;
