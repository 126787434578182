import styled from "styled-components";
import { SchoolPupilSubscription } from "../../../generated/graphql";
import { TODAY_DATE } from "../SchoolSubscription/utils";
import { CombinedPaymentTypeMap } from "../SchoolSubscription/maps";
import {
  formatDate,
  getDaysLeft,
} from "../SchoolSubscription/PaymentItem/PaymentItem";

export const PaymentItemBlock = styled.ul`
  margin-top: 14px;
  padding: 0;
  list-style-type: none;
  min-width: 460px;
`;

export const PaymentItemElement = styled.li`
  margin-top: 4px;
`;

interface SchoolPupilsPaymentItemProps {
  schoolPupilsSubscription: SchoolPupilSubscription;
}

export const SchoolPupilsPaymentItem = ({
  schoolPupilsSubscription,
}: SchoolPupilsPaymentItemProps) => {
  const { paymentDate, expiredDate, subscriptionPlan, price } =
    schoolPupilsSubscription;
  const monthsCount =
    schoolPupilsSubscription.schoolPupilSubscriptionPeriod.monthCount;

  return (
    <PaymentItemBlock>
      <PaymentItemElement>
        <b>Наданий доступ: </b> <span>{formatDate(paymentDate)}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Підписка доступна до: </b>{" "}
        <span>
          {monthsCount === -1
            ? "залежно від підписки простору"
            : formatDate(expiredDate)}
        </span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Підписка закінчується через: </b>
        <span>
          {monthsCount === -1
            ? "залежно від підписки простору"
            : `${getDaysLeft(expiredDate, TODAY_DATE)}`}
        </span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тариф: </b> <span>{subscriptionPlan.description}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Кількість місяців: </b>
        <span>
          {monthsCount === -1
            ? "залежно від підписки простору"
            : `${monthsCount} міс.`}
        </span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тип платежу: </b>{" "}
        <span>
          {CombinedPaymentTypeMap.get(
            schoolPupilsSubscription.paymentType.name
          ) || schoolPupilsSubscription.paymentType.name}
        </span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Вартість: </b>{" "}
        <span>{`${price} ${
          process.env.REACT_APP_CURRENT_LOCALE === "en" ? "$" : "грн"
        }`}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Платник: </b>{" "}
        <span>{schoolPupilsSubscription.schoolPayer.name}</span>
      </PaymentItemElement>
    </PaymentItemBlock>
  );
};
