import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {
    Backdrop,
    Box,
    Checkbox,
    CircularProgress,
    Container,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";

import {Header} from "../shared/Style/Style";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";
import {useStyles} from "../Login";
import {useRequestError} from "../../hooks";
import {
    AddPermissionByUserIdDocument,
    AddPermissionByUserIdMutation,
    GetUserFeatureFlagsByUserIdDocument,
    GetUserFeatureFlagsByUserIdQuery,
    RemovePermissionByUserIdDocument,
    RemovePermissionByUserIdMutation
} from "../../generated/graphql";
import {SuccessSnackbar} from "../shared/SuccessSnackbar";

export const UserFeatureFlags = () => {
    const { userId } = useParams<{ userId: string }>();
    const classes = useStyles();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // Query to get user feature flags
    const {
        data: featureFlagsData,
        loading: featureFlagsLoading,
        error: featureFlagsError,
        refetch: refetchFeatureFlags,
    } = useQuery<GetUserFeatureFlagsByUserIdQuery>(GetUserFeatureFlagsByUserIdDocument, {
        variables: { userId },
    });

    // Mutation to add permission
    const [
        addPermission,
        { loading: addPermissionLoading, error: addPermissionError },
    ] = useMutation<AddPermissionByUserIdMutation>(AddPermissionByUserIdDocument);

    // Mutation to remove permission
    const [
        removePermission,
        { loading: removePermissionLoading, error: removePermissionError },
    ] = useMutation<RemovePermissionByUserIdMutation>(RemovePermissionByUserIdDocument);

    // Handle errors
    const error = featureFlagsError || addPermissionError || removePermissionError;
    const { requestError, setRequestError } = useRequestError(error);

    // Handle loading state
    const isLoading = featureFlagsLoading || addPermissionLoading || removePermissionLoading;

    // Handle checkbox change
    const handleTogglePermission = async (flagId: string, isGranted: boolean) => {
        try {
            if (isGranted) {
                await removePermission({
                    variables: { userId, flagId },
                });
                setSuccessMessage("Разрешение успешно удалено");
            } else {
                await addPermission({
                    variables: { userId, flagId },
                });
                setSuccessMessage("Разрешение успешно добавлено");
            }
            await refetchFeatureFlags();

            // Clear success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        } catch (error) {
            console.error(error);
            setRequestError(true);
        }
    };

    // Show loading spinner when data is loading
    if (isLoading) {
        return (
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <>
            <Container maxWidth="lg">
                <Header>Управление разрешениями пользователя</Header>
                <Paper elevation={3}>
                    <Box p="18px 32px 32px" mt="40px">
                        <Typography variant="h6" gutterBottom>
                            Список доступных разрешений
                        </Typography>
                        <List>
                            {featureFlagsData?.getUserFeatureFlagsByUserId?.map((flag) => (
                                <ListItem key={flag.id} button onClick={() => handleTogglePermission(flag.id, flag.isGranted)}>
                                    <Checkbox checked={flag.isGranted} color="primary" />
                                    <ListItemText primary={flag.name} secondary={flag.flag} />
                                </ListItem>
                            ))}
                            {(!featureFlagsData?.getUserFeatureFlagsByUserId || featureFlagsData.getUserFeatureFlagsByUserId.length === 0) && (
                                <ListItem>
                                    <ListItemText primary="Нет доступных разрешений" />
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </Paper>
            </Container>

            {/* Error snackbar */}
            <ErrorMessageSnackbar
                open={requestError}
                message={
                    (featureFlagsError && "Ошибка при загрузке разрешений") ||
                    (addPermissionError && "Ошибка при добавлении разрешения") ||
                    (removePermissionError && "Ошибка при удалении разрешения") ||
                    "Произошла ошибка"
                }
                closeHandler={() => setRequestError(false)}
            />

            {/* Success snackbar */}
            {successMessage && (
                <SuccessSnackbar
                    open={true}
                    handleClose={() => setSuccessMessage(null)}
                    message={successMessage}
                    snackbarProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                />
            )}
        </>
    );
};