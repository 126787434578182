import { ChangeEvent } from "react";
import { Tabs, Tooltip } from "@material-ui/core";
import { CustomTab, TabIndicatorProps } from "./Styles";
import { TabList } from "../School/UpdateSchool";

interface BasicTabsProps {
  tabList?: TabList[];
  activeTab: number;
  onTabChange: (newValue: number) => void;
}

export const BasicTabs = ({
  tabList,
  activeTab,
  onTabChange,
}: BasicTabsProps) => {
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    if (tabList[newValue]?.disabled) return;
    onTabChange(newValue);
  };

  if (!tabList) return null;

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={TabIndicatorProps}
      >
        {tabList.map(({ tabName, disabled, tooltipText }, index) => {
          const tabElement = (
            <CustomTab
              key={tabName}
              label={tabName}
              $active={activeTab === index}
              $disabled={disabled}
              disabled={disabled}
            />
          );

          return disabled && tooltipText ? (
            <Tooltip key={tabName} title={tooltipText} arrow>
              <span>{tabElement}</span>
            </Tooltip>
          ) : (
            tabElement
          );
        })}
      </Tabs>

      {tabList.map(({ tabName, TabPanel }, index) => (
        <div key={tabName} hidden={activeTab !== index}>
          {activeTab === index && TabPanel}
        </div>
      ))}
    </>
  );
};
