import { useLazyQuery } from "@apollo/client";
import {
  GetSchoolUsersCsvDocument,
  GetSchoolUsersCsvQuery,
  GetValidateUserInvitationsListDocument,
  GetValidateUserInvitationsListQuery,
} from "../../../generated/graphql";

export const useGetValidatedUserInvitationsList = () => {
  const [
    validateUserInvitationsList,
    {
      data: validateUserListData,
      loading: validateUserListLoading,
      error: validateUserListError,
    },
  ] = useLazyQuery<GetValidateUserInvitationsListQuery>(
    GetValidateUserInvitationsListDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    validateUserInvitationsList,
    validateUserListData,
    validateUserListLoading,
    validateUserListError,
  };
};

export const useGetLazySchoolUsersCsvFile = () => {
  const [
    schoolUsersCsv,
    {
      data: schoolUsersCsvData,
      loading: schoolUsersCsvLoading,
      error: schoolUsersCsvError,
    },
  ] = useLazyQuery<GetSchoolUsersCsvQuery>(GetSchoolUsersCsvDocument, {
    fetchPolicy: "cache-and-network",
  });

  return {
    schoolUsersCsv,
    schoolUsersCsvData,
    schoolUsersCsvLoading,
    schoolUsersCsvError,
  };
};
