import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Form } from "react-final-form";
import Cookies from "js-cookie";
import { IsFromEnum, useLoginFromAdminMutation } from "../../generated/graphql";
import { LoginEmailFormType } from "./Login.type";
import { Forgot } from "./Forgot";
import { LoginForm } from "./LoginForm";
import { SnackMessage } from "../shared/Modal/SnackMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { Box, Toolbar } from "@material-ui/core";
import { ButtonCustom, primary } from "../shared/Style/Style";
import {
  AppBarStyle,
  ButtonForgotStyle,
  ButtonRegister,
  Container,
  ForgotContainer,
  LoginContainer,
  LoginContainerPromo,
  LoginLink,
  LoginTitle,
} from "./Login.style";
import { COOKIE_NAME_TOKEN } from '../../constants/cookies';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 100,
      color: `${primary}`,
    },
  })
);

const initialValues: LoginEmailFormType = {
  email: "",
  password: "",
  isFrom: IsFromEnum.AdminPanel
};

export interface InitialErrorsValues {
  emailError: string;
  passwordError: string;
}

const initialErrorsValues = {
  emailError: "",
  passwordError: "",
};

export const Login = () => {
  const [openForgot, setOpenForgot] = useState(false);
  const [successForgot, setSuccessForgot] = useState(false);

  const [requiredFieldsError, setRequiredFieldsError] =
    useState(initialErrorsValues);

  const [login, { data, loading, error }] = useLoginFromAdminMutation({
    awaitRefetchQueries: true,
  });

  const classes = useStyles();

  useEffect(() => {
    const success = setTimeout(() => setSuccessForgot(false), 5000);
    return () => clearTimeout(success);
  }, [successForgot]);

  useEffect(() => {});

  if (data?.loginFromAdmin?.token) {
    Cookies.set(COOKIE_NAME_TOKEN, data.loginFromAdmin.token, { expires: 1 });
    return <Redirect to="/" />;
  }

  const handleOpenForgot = () => {
    setOpenForgot(true);
  };

  const handleCloseForgot = () => {
    setOpenForgot(false);
  };

  return (
    <Container>
      <AppBarStyle position="fixed">
        <Toolbar />
      </AppBarStyle>
      <LoginContainer>
        <LoginTitle>Вход</LoginTitle>
        <ForgotContainer>
          <ButtonForgotStyle onClick={handleOpenForgot} size={"small"}>
            Забыли пароль
          </ButtonForgotStyle>
        </ForgotContainer>
        <Forgot
          handleCloseForgot={handleCloseForgot}
          openForgot={openForgot}
          setSuccessForgot={setSuccessForgot}
        />
        <Form onSubmit={() => {}} initialValues={initialValues}>
          {({ invalid, values }) => {
            return (
              <>
                <LoginForm
                  onSubmit={async () => {
                    !invalid &&
                      (await login({
                        variables: {
                          email: values.email,
                          password: values.password,
                        },
                      }));
                  }}
                  requiredFieldsError={requiredFieldsError}
                  setRequiredFieldsError={setRequiredFieldsError}
                />

                <LoginContainerPromo>
                  <Box mt={1}>
                    <ButtonCustom
                      type="submit"
                      variant="contained"
                      size="large"
                      onClick={async () => {
                        setRequiredFieldsError({
                          emailError: !values.email ? "Обязательное поле" : "",
                          passwordError: !values.password
                            ? "Обязательное поле"
                            : "",
                        });

                        !invalid &&
                        values.email &&
                        values.password &&
                          (await login({
                            variables: {
                              email: values.email,
                              password: values.password,
                            },
                          }));
                      }}
                    >
                      Войти
                    </ButtonCustom>
                  </Box>
                </LoginContainerPromo>
              </>
            );
          }}
        </Form>

        <LoginContainerPromo>
          <LoginLink to="/register">
            <ButtonRegister size="small">Зарегистрироваться</ButtonRegister>
          </LoginLink>
          {loading && (
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {(error?.message === "Unauthorized" && (
            <SnackMessage
              message={"Неверно указан email или пароль"}
              type={"error"}
            />
          )) ||
          (error?.message === "Forbidden: not verified" || error?.message === "Not Allowed: pending") && (
            <SnackMessage
              message={
                "Профиль не подтвержден. Обратитесь к HR-менеджеру для подтверждения."
              }
              type={"error"}
            />
          )}

          {successForgot && (
            <SnackMessage
              message={`На ваш email была отправлена инструкция по восстановлению пароля.`}
              type={"success"}
            />
          )}
        </LoginContainerPromo>
      </LoginContainer>
    </Container>
  );
};
