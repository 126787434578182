import React, {
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { FiltersSelect } from "../shared/Selects/FiltersSelect";
import { SchoolsFiltersType } from "./types";
import {
  endOfSubscriptionOptions,
  subscriptionTypesOptions,
} from "./constants";
import styled from "styled-components";
import { FilterRangeInput } from "../shared/Inputs/FilterRangeInput";
import { useDebounce } from "../shared/Utils/OtherOperations/useDebounce";
import { primary } from "../shared/Style/Style";

interface SchoolsFiltersProps {
  setFilterVariables: Dispatch<SetStateAction<SchoolsFiltersType>>;
  filterVariables: SchoolsFiltersType;
  isLoading?: boolean;
}

export const SchoolsFilters: FC<SchoolsFiltersProps> = ({
  setFilterVariables,
  filterVariables,
  isLoading,
}) => {
  const [localFilterVariables, setLocalFilterVariables] =
    useState<SchoolsFiltersType>(filterVariables);

  const debouncedFilterVariables = useDebounce(localFilterVariables, 1000);

  useEffect(() => {
    setFilterVariables(debouncedFilterVariables);
  }, [debouncedFilterVariables, setFilterVariables]);

  const handleEndOfSubscriptionFilter = useCallback((value) => {
    setLocalFilterVariables((prevState) => ({
      ...prevState,
      daysRangeUntilLicenseExpiration: value,
    }));
  }, []);

  const handleSubscriptionTypeFilter = useCallback((value) => {
    setLocalFilterVariables((prevState) => ({
      ...prevState,
      subscriptionStatus: value,
    }));
  }, []);

  const handleFilter = useCallback(
    (objectValues) =>
      setLocalFilterVariables((prevState) => ({
        ...prevState,
        ...objectValues,
      })),

    []
  );

  return (
    <div>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box marginRight="8px">
          Виключити школи для внутрішнього користування
        </Box>
        <Checkbox
          style={{
            color: primary,
          }}
          checked={filterVariables.excludeTestSchools}
          onChange={({ target: { checked } }) =>
            handleFilter({
              excludeTestSchools: checked,
            })
          }
        />
      </Box>
      <StyledSchoolsFiltersContainer>
        <FiltersSelect
          fieldsData={endOfSubscriptionOptions}
          handleSetFilter={handleEndOfSubscriptionFilter}
          overrideFormControlStyles={{ margin: 0 }}
          defaultValue={filterVariables?.daysRangeUntilLicenseExpiration}
          disabled={isLoading}
        />
        <FiltersSelect
          fieldsData={subscriptionTypesOptions}
          handleSetFilter={handleSubscriptionTypeFilter}
          overrideFormControlStyles={{ width: 220 }}
          defaultValue={filterVariables?.subscriptionStatus}
          disabled={isLoading}
        />
        <FilterRangeInput
          keyValue="PupilsCount"
          title="Кількість учнів"
          minInputPlaceholder="чол"
          maxInputPlaceholder="чол"
          handleApplyFilter={handleFilter}
          displayInputValueInTitle
          defaultMinValue={filterVariables?.minPupilsCount}
          defaultMaxValue={filterVariables?.maxPupilsCount}
          showButton
          disabled={isLoading}
        />
        <FilterRangeInput
          keyValue="TeachersCount"
          title="Кількість вчителів"
          minInputPlaceholder="чол"
          maxInputPlaceholder="чол"
          handleApplyFilter={handleFilter}
          displayInputValueInTitle
          defaultMinValue={filterVariables?.minTeachersCount}
          defaultMaxValue={filterVariables?.maxTeachersCount}
          showButton
          disabled={isLoading}
        />
      </StyledSchoolsFiltersContainer>
    </div>
  );
};

const StyledSchoolsFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;
