import React from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { CloseIconButton } from "../../../shared/Buttons/CloseIconButton";
import {
  black,
  ButtonCustom,
  CancelButton,
  primary,
} from "../../../shared/Style/Style";
import { StyledDialog, StyledDialogTitle } from "./ConfirmUnpublishModal.style";

interface ConfirmUnpublishModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmUnpublishModal = ({
  onClose,
  onConfirm,
}: ConfirmUnpublishModalProps) => {
  return (
    <StyledDialog open onClose={onClose}>
      <StyledDialogTitle>
        Подтверждение
        <CloseIconButton close={onClose} main_color={black} />
      </StyledDialogTitle>

      <DialogContent>
        <DialogContentText>
          Коллекция используется в школах. При снятии с публикации все школы
          автоматически перейдут на стиль AR Book. Продолжить?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose}>Отменить</CancelButton>
        <ButtonCustom onClick={onConfirm} main_color={primary}>
          Продолжить
        </ButtonCustom>
      </DialogActions>
    </StyledDialog>
  );
};
