import React, { FC, memo, MouseEvent, useState } from "react";

import { useMutation } from "@apollo/client";
import { GetUsersDocument, DeleteUserFromAdminDocument, GetUsersInput, User } from "../../../generated/graphql";

import {IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { Confirmation } from "./ConfirmDialog";

import { MenuItemStyle, StyledLink } from "../Style/Style";

const ITEM_HEIGHT = 48;

interface IUserMenuProps {
  user: User;
  getUserData: GetUsersInput;
}

export const UserMenu: FC<IUserMenuProps> = memo(
  ({ user, getUserData }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteMutation] = useMutation(DeleteUserFromAdminDocument, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetUsersDocument,
          variables: {
            getUsersData: {
              ...getUserData,
            },
          },
        },
      ],
    });

    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItemStyle prim={false} onClick={handleClose}>
            <StyledLink to={`/user/${user.id}/edit`}>Редактировать</StyledLink>
          </MenuItemStyle>
          <Confirmation>
            {(confirmFn: (cb: () => void) => void) => {
              return (
                <MenuItemStyle
                  prim={true}
                  onClick={(e) => {
                    e.stopPropagation();

                    handleClose();
                    confirmFn(() => async () => {
                      await deleteMutation({
                        variables: {
                          userId: user?.id,
                        },
                      });
                    });
                  }}
                >
                  Удалить
                </MenuItemStyle>
              );
            }}
          </Confirmation>
        </Menu>
      </div>
    );
  }
);
