import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "@material-ui/core";
import {
  CollectionsHeadTableCell,
  CollectionsTableRow,
  CollectionTableCell,
  CollectionTableImage,
} from "./CollectionsTable.style";
import { COLLECTION_TABLE_HEADER } from "./constants";
import { Menu } from "../../../shared/Menu";
import {
  GetWhiteLabelCollectionsDocument,
  useCheckIfWhiteLabelCollectionIsInUseMutation,
  useDeleteWhiteLabelCollectionMutation,
  useGetWhiteLabelCollectionsQuery,
  usePublishedWhiteLabelCollectionMutation,
} from "../../../../generated/graphql";
import { LoadingProgress } from "../../../shared/LoadingProgress";
import { primary, red } from "../../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { ConfirmUnpublishModal } from "../ConfirmUnpublishModal/ConfirmUnpublishModal";

export const CollectionsTable = () => {
  const { push } = useHistory();
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [pendingStatusChange, setPendingStatusChange] = useState(false);

  const { data, loading } = useGetWhiteLabelCollectionsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [publishedCollection, { loading: publishedCollectionLoading }] =
    usePublishedWhiteLabelCollectionMutation();

  const [deleteCollection, { loading: deleteCollectionLoading }] =
    useDeleteWhiteLabelCollectionMutation();

  const [checkIfCollectionIsUsed, { loading: checkIfCollectionIsUsedLoading }] =
    useCheckIfWhiteLabelCollectionIsInUseMutation();

  const handlePublicationError = () => {
    setRequestError(true);
    setErrorMessage("Ошибка при изменении статуса публикации");
  };

  const updateCollectionStatus = (id: string, isPublished: boolean) => {
    publishedCollection({
      variables: {
        updateWhiteLabelCollectionId: id,
        isPublished,
      },
      refetchQueries: [GetWhiteLabelCollectionsDocument],
    });

    setOpenConfirmModal(false);
    setSelectedCollectionId(null);
  };

  const togglePublishedHandler = async (id: string, isPublished: boolean) => {
    if (!id) return;

    try {
      if (!isPublished) {
        const { data } = await checkIfCollectionIsUsed({
          variables: { checkIfWhiteLabelCollectionIsInUseId: id },
        });

        if (data?.checkIfWhiteLabelCollectionIsInUse) {
          setSelectedCollectionId(id);
          setPendingStatusChange(false);
          setOpenConfirmModal(true);
          return;
        }
      }

      await updateCollectionStatus(id, isPublished);
    } catch (e) {
      handlePublicationError();
    }
  };

  const confirmStatusChange = async () => {
    if (!selectedCollectionId) return;

    try {
      await updateCollectionStatus(selectedCollectionId, pendingStatusChange);
    } catch (e) {
      handlePublicationError();
    }
  };

  const deleteCollectionHandler = async (id: string) => {
    try {
      await deleteCollection({
        variables: {
          deleteWhiteLabelCollectionId: id,
        },
        refetchQueries: [GetWhiteLabelCollectionsDocument],
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("Ошибка при удалении коллекции");
    }
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setSelectedCollectionId(null);
  };

  const menuItem = (id: string, isPublished: boolean) => [
    {
      name: !isPublished ? "Опубликовать" : "Снять с публикации",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        togglePublishedHandler(id, !isPublished);
      },
    },
    {
      name: "Редактировать",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        push(`/collections/${id}`);
      },
    },
    {
      name: "Удалить",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        deleteCollectionHandler(id);
      },
      isDisabled: isPublished,
      color: "red",
    },
  ];

  const collectionsWhiteLabel = data?.getWhiteLabelCollections ?? [];

  if (
    loading ||
    publishedCollectionLoading ||
    deleteCollectionLoading ||
    checkIfCollectionIsUsedLoading
  ) {
    return <LoadingProgress />;
  }

  if (!collectionsWhiteLabel.length) return null;

  return (
    <>
      <Table>
        <thead>
          <CollectionsTableRow>
            {COLLECTION_TABLE_HEADER.map((el, i) => (
              <CollectionsHeadTableCell key={i}>{el}</CollectionsHeadTableCell>
            ))}
          </CollectionsTableRow>
        </thead>
        <tbody>
          {collectionsWhiteLabel.map(
            ({ id, name, description, systemLogo, image, isPublished }) => (
              <CollectionsTableRow key={id}>
                <CollectionTableImage prim={image || systemLogo} />
                <CollectionTableCell>{name}</CollectionTableCell>
                <CollectionTableCell>{description}</CollectionTableCell>
                <CollectionTableCell
                  style={{ color: isPublished ? primary : red }}
                >
                  {isPublished ? "Опубликовано" : "Не опубликовано"}
                </CollectionTableCell>
                <CollectionTableCell>
                  <Menu
                    vertical={36}
                    horizontal={12}
                    width={170}
                    items={menuItem(id, isPublished)}
                  />
                </CollectionTableCell>
              </CollectionsTableRow>
            )
          )}
        </tbody>
      </Table>

      {openConfirmModal && selectedCollectionId && (
        <ConfirmUnpublishModal
          onClose={handleCloseConfirmModal}
          onConfirm={confirmStatusChange}
        />
      )}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
