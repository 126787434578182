import { useState } from "react";
import {
  School as SchoolModel,
  useUpdateSchoolMutation,
} from "../../../generated/graphql";
import { useSetSchoolData } from "../hooks";
import { Box, Paper, Snackbar } from "@material-ui/core";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { primary } from "../../shared/Style/Style";
import Checkbox from "@material-ui/core/Checkbox";
import { SchoolPupilsSubscription } from "./SchoolPupilsSubscription";
import { Alert } from "@material-ui/lab";

export interface SnackbarInterface {
  open: boolean;
  message: string;
  severity: "success" | "error";
}

interface SchoolPupilsSubscriptionsProps {
  disable: boolean;
  schoolDataUpdate?: SchoolModel;
}

export const SchoolPupilsSubscriptions = ({
  disable,
  schoolDataUpdate,
}: SchoolPupilsSubscriptionsProps) => {
  const [snackbar, setSnackbar] = useState<SnackbarInterface>({
    open: false,
    message: "",
    severity: "success",
  });
  const [updateSchool] = useUpdateSchoolMutation({
    onCompleted: () => {
      setSnackbar({
        open: true,
        message: "Школу успішно оновлено!",
        severity: "success",
      });
    },
    onError: (err) => {
      setSnackbar({
        open: true,
        message: "Не вдалося оновити школу!",
        severity: "error",
      });
    },
  });
  const { schoolData, setSchoolData } = useSetSchoolData(schoolDataUpdate);
  const { id, isInternalUse } = schoolData;

  const handleIsInternalUse = (isInternalUse: boolean) => {
    setSchoolData((prev) => ({
      ...prev,
      isInternalUse,
    }));

    updateSchool({
      variables: {
        id,
        schoolData: {
          isInternalUse,
        },
      },
    });
  };

  if (disable) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={1000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="50%"
          m={0}
        >
          <p>Школа для внутрішнього користування</p>
          <Checkbox
            style={{
              color: primary,
            }}
            checked={isInternalUse}
            onChange={({ target: { checked } }) => handleIsInternalUse(checked)}
          />
        </Box>
      </Box>
      <Paper elevation={3}>
        <Box p="20px 30px">
          <Box flex="0 0 50%">
            <SchoolPupilsSubscription />
          </Box>
        </Box>
      </Paper>
    </>
  );
};
