import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import {
  useCreateSchoolPupilSubscriptionMutation,
  useGetAdminPanelSubscriptionsPaymentTypeQuery,
  useGetSchoolPayersQuery,
  useGetSchoolPupilSubscriptionPeriodQuery,
  useGetSchoolPupilSubscriptionPlansQuery,
  useGetSchoolPupilSubscriptionQuery,
} from "../../../generated/graphql";
import { SubscriptionFormValues } from "../SchoolSubscription/SubscriptionFormsForSchools/utils";
import { ButtonCustom, ModalInput } from "../../shared/Style/Style";
import {
  commonPaymentTypeMap,
  subscriptionPlansMonths,
} from "../SchoolSubscription/maps";
import { costText } from "../constants";
import { useState } from "react";
import { Alert } from "@material-ui/lab";
import { SnackbarInterface } from "./SchoolPupilsSubscriptions";

const formInitialValues: SubscriptionFormValues = {
  paymentDate: "",
  validity: "",
  paymentType: "",
  planId: "",
  total: "",
};

export const CreateSchoolPupilsSubscription = () => {
  const [snackbar, setSnackbar] = useState<SnackbarInterface>({
    open: false,
    message: "",
    severity: "success",
  });
  const { schoolId = "" } = useParams<{ schoolId: string }>();
  const { data: subscriptionPlansData } =
    useGetSchoolPupilSubscriptionPlansQuery();

  const subscriptionPlans =
    subscriptionPlansData?.getSchoolPupilSubscriptionPlans ?? [];

  const { data: paymentTypeData } =
    useGetAdminPanelSubscriptionsPaymentTypeQuery();
  const { data: schoolPayerData } = useGetSchoolPayersQuery({
    fetchPolicy: "cache-and-network",
  });

  const { data: schoolPupilSubscriptionPeriodData } =
    useGetSchoolPupilSubscriptionPeriodQuery();

  const { refetch: refetchSchoolPupilsSubscriptionsData } =
    useGetSchoolPupilSubscriptionQuery({
      variables: { schoolId },
    });

  const [createSubscription] = useCreateSchoolPupilSubscriptionMutation({
    onCompleted: () => {
      refetchSchoolPupilsSubscriptionsData();
      setSnackbar({
        open: true,
        message: "Підписку успішно створено!",
        severity: "success",
      });
    },
    onError: (err) => {
      console.error("Помилка створення підписки:", err);
      setSnackbar({
        open: true,
        message: "Не вдалося створити підписку!",
        severity: "error",
      });
    },
  });

  const paymentTypes =
    paymentTypeData?.getAdminPanelSubscriptionsPaymentType ?? [];

  const schoolPayer = schoolPayerData?.getSchoolPayers ?? [];

  const schoolPupilSubscriptionPeriods =
    schoolPupilSubscriptionPeriodData?.getSchoolPupilSubscriptionPeriod ?? [];

  const validateForm = (values: SubscriptionFormValues) => {
    const errors: { [key: string]: string } = {};

    const isInvalidNumber = (value: any, min = 0) =>
      isNaN(Number(value)) || Number(value) < min;

    [
      "paymentDate",
      "planId",
      "paymentTypeId",
      "payerId",
      "total",
      "validity",
    ].forEach((field) => {
      if (!values[field]) {
        errors[field] = "Це поле обов'язкове";
      }
    });

    if (values.total?.length && isInvalidNumber(values.total)) {
      errors.total = "Значення має бути числом і не менше 0";
    }

    return errors;
  };

  const onFormValidation = (values: SubscriptionFormValues) =>
    validateForm(values);

  const onFormSubmit = (values: SubscriptionFormValues) => {
    const { paymentDate, validity, paymentTypeId, planId, total, payerId } =
      values;

    createSubscription({
      variables: {
        data: {
          schoolId,
          planId,
          schoolPupilSubscriptionPeriodId: validity,
          schoolPayerId: payerId,
          paymentDate: new Date(paymentDate),
          paymentTypeId,
          price: Number(total),
        },
      },
    });
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={1000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Form
        initialValues={formInitialValues}
        validate={onFormValidation}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid xs={6} item>
                <Field name="paymentDate">
                  {({ input, meta }) => (
                    <DatePicker
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      fullWidth
                      variant="inline"
                      format="dd.MM.yyyy"
                      label="Дата платежу"
                      inputVariant="filled"
                      value={input.value || null}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="validity">
                  {({ input, meta }) => (
                    <FormControl
                      variant="filled"
                      error={meta.error && meta.touched}
                      fullWidth
                    >
                      <InputLabel>Кількість місяців</InputLabel>
                      <Select
                        fullWidth
                        value={input.value.id}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                        }}
                      >
                        {schoolPupilSubscriptionPeriods.map(
                          ({ id, displayName }) => (
                            <MenuItem key={id} value={id}>
                              {displayName}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {meta.error && meta.touched ? (
                        <FormHelperText>{meta.error}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="planId">
                  {({ input, meta }) => (
                    <FormControl
                      variant="filled"
                      error={meta.error && meta.touched}
                      fullWidth
                    >
                      <InputLabel>Тариф</InputLabel>
                      <Select
                        fullWidth
                        value={input.value.id}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                        }}
                      >
                        {subscriptionPlans.map(({ id, name, description }) => (
                          <MenuItem key={id} value={id}>
                            {`${description ?? name} ${
                              subscriptionPlansMonths[name]?.label || ""
                            }`}
                          </MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched ? (
                        <FormHelperText>{meta.error}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="paymentTypeId">
                  {({ input, meta }) => (
                    <FormControl
                      variant="filled"
                      error={meta.error && meta.touched}
                      fullWidth
                    >
                      <InputLabel>Тип платежу</InputLabel>
                      <Select
                        fullWidth
                        value={input.value.id}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                        }}
                      >
                        {paymentTypes.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {commonPaymentTypeMap[name]}
                          </MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched ? (
                        <FormHelperText>{meta.error}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="total">
                  {({ input, meta }) => (
                    <ModalInput
                      label={costText}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      variant="filled"
                      type="number"
                      {...input}
                    />
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="payerId">
                  {({ input, meta }) => (
                    <FormControl
                      variant="filled"
                      error={meta.error && meta.touched}
                      fullWidth
                    >
                      <InputLabel>Платник</InputLabel>
                      <Select
                        fullWidth
                        value={input.value.id}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                        }}
                      >
                        {schoolPayer.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched ? (
                        <FormHelperText>{meta.error}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid xs={12} item>
                <ButtonCustom type="submit" width="200">
                  Оформити підписку
                </ButtonCustom>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </>
  );
};
