import { useState } from "react";
import { useParams } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  useDeleteSchoolPupilSubscriptionMutation,
  useGetSchoolPupilSubscriptionQuery,
  useUpdateSchoolPupilSubscriptionMutation,
} from "../../../generated/graphql";
import { Box, Paper } from "@material-ui/core";
import { Subtitle, StyledSwitch } from "../../shared/Style/Style";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { PaymentsContainer } from "../Style";
import { SchoolPupilsPaymentItem } from "./SchoolPupilsPaymentItem";
import { SnackbarInterface } from "./SchoolPupilsSubscriptions";

export const SchoolPupilsPayments = () => {
  const [snackbar, setSnackbar] = useState<SnackbarInterface>({
    open: false,
    message: "",
    severity: "success",
  });

  const { schoolId } = useParams<{ schoolId: string }>();
  const {
    data: schoolPupilsSubscriptionsData,
    refetch: refetchSchoolPupilsSubscriptionsData,
  } = useGetSchoolPupilSubscriptionQuery({
    variables: { schoolId },
  });
  const [updateSubscription] = useUpdateSchoolPupilSubscriptionMutation({
    onCompleted: () => {
      refetchSchoolPupilsSubscriptionsData();
      setSnackbar({
        open: true,
        message: "Підписку успішно оновлено!",
        severity: "success",
      });
    },
    onError: (err) => {
      setSnackbar({
        open: true,
        message: "Не вдалося оновити підписку!",
        severity: "error",
      });
    },
  });

  const [deleteSubscription] = useDeleteSchoolPupilSubscriptionMutation({
    onCompleted: () => {
      refetchSchoolPupilsSubscriptionsData();
      setSnackbar({
        open: true,
        message: "Підписку успішно видалено!",
        severity: "success",
      });
    },
    onError: (err) => {
      setSnackbar({
        open: true,
        message: "Не вдалося видалити підписку!",
        severity: "error",
      });
    },
  });

  const handleDelete = async (deleteSchoolPupilSubscriptionId: string) => {
    try {
      await deleteSubscription({
        variables: { deleteSchoolPupilSubscriptionId },
      });
    } catch (err) {
      console.error("Помилка deleteSubscription:", err);
    }
  };

  const schoolPupilsSubscriptions =
    schoolPupilsSubscriptionsData?.getSchoolPupilSubscription ?? [];

  const handleUpdate = async (
    updateSchoolPupilSubscriptionId: string,
    isActive: boolean
  ) => {
    try {
      await updateSubscription({
        variables: {
          updateSchoolPupilSubscriptionId,
          isActive,
        },
      });
    } catch (err) {
      console.error("Помилка updateSubscription:", err);
    }
  };

  if (!schoolPupilsSubscriptions?.length) return null;

  return (
    <Paper elevation={3}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={1000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <PaymentsContainer>
        <>
          <Subtitle>Перелік оплат</Subtitle>
          {schoolPupilsSubscriptions?.map((schoolPupilsSubscription) => {
            const { id, isActive } = schoolPupilsSubscription;
            return (
              <Box key={id} display="flex">
                <SchoolPupilsPaymentItem
                  schoolPupilsSubscription={schoolPupilsSubscription}
                />
                <Box paddingLeft="18px">
                  <Box display="flex" alignItems="center">
                    <p>{!isActive ? "Надати доступ" : "Відкликати доступ"}</p>
                    <StyledSwitch
                      checked={isActive}
                      onChange={() => handleUpdate(id, !isActive)}
                    />
                  </Box>
                  <Box display="flex">
                    <p>Удалить платеж</p>
                    <DeleteIconButton
                      item="платеж"
                      fz={20}
                      deleteHandler={() => handleDelete(id)}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </>
      </PaymentsContainer>
    </Paper>
  );
};
