import { SchoolPaths } from "../../shared/constants";

export const SIDEBAR_ROUTES = [
  {
    link: "/dashboard",
    name: "Дашборд",
    icon: "Dashboard",
    feature: "Dashboard",
  },
  {
    link: "/projects",
    name: "Проекты",
    icon: "Projects",
    feature: "Projects",
  },
  {
    link: "/problemTasks",
    name: "Проблемные задачи",
    icon: "ProblemTasks",
    feature: "ProblemTasks",
  },
  {
    link: "/overdueTasks",
    name: "Просроченные задачи",
    icon: "OverdueTasks",
    feature: "OverdueTasks",
  },
  {
    link: "/people",
    name: "Люди",
    icon: "People",
    feature: "People",
  },
  {
    link: `/${SchoolPaths.school}s`,
    name: "Школы",
    icon: "Schools",
    feature: "Schools",
  },
  {
    link: `/${SchoolPaths.personal}s`,
    name: "Персональные школы",
    icon: "PersonalSchools",
    feature: "PersonalSchools",
  },
  {
    link: "/events",
    name: "Мероприятия",
    icon: "Events",
    feature: "Events",
  },
  {
    link: "/training",
    name: "Физкультура",
    icon: "Training",
    feature: "Training",
  },
  {
    link: "/reports",
    name: "Отчеты",
    icon: "Reports",
    feature: "Reports",
  },
  {
    link: "/reportsARBook/teachersPupils",
    name: "Отчеты по AR_Book",
    icon: "ReportsARBook",
    feature: "ReportsARBook",
  },
  {
    link: "/mobileApps",
    name: "Приложение",
    icon: "MobileApps",
    feature: "MobileApps",
  },
  {
    link: "/mobileAppUsers",
    name: "Пользователи приложения",
    icon: "MobileAppUsers",
    feature: "MobileAppUsers",
  },
  {
    link: "/sites",
    name: "Сайты",
    icon: "Sites",
    feature: "Sites",
  },
  {
    link: "/announcements/notifications",
    name: "Оповещения",
    icon: "Notifications",
    feature: "Notifications",
  },
  {
    link: "/knowledgeBase",
    name: "База знаний",
    icon: "KnowledgeBase",
    feature: "KnowledgeBase",
  },
  {
    link: "/feedback",
    name: "Обратная связь",
    icon: "Feedback",
    feature: "Feedback",
  },
  {
    link: "/giveaway",
    name: "Розыгрыш",
    icon: "Giveaway",
    feature: "Giveaway",
  },
  {
    link: "/askedQuestions",
    name: "Частые Вопросы",
    icon: "AskedQuestions",
    feature: "AskedQuestions",
  },
  {
    link: "/settings",
    name: "Настройки",
    icon: "Settings",
    feature: "Settings",
  },
  {
    link: "/onboarding",
    name: "Онбординг",
    icon: "Onboarding",
    feature: "Onboarding",
  },
  {
    link: "/collections",
    name: "Коллекции",
    icon: "WhiteLabel",
    feature: "WhiteLabel",
  },
  {
    link: "/books",
    name: "Книги",
    icon: "Books",
    feature: "Books",
  },
];
