import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Table } from "@material-ui/core";
import { ButtonCustom, Header, HiddenInput } from "../shared/Style/Style";
import {
  GetContentTextbooksDocument,
  useDeleteContentTextbookMutation,
  useGetContentTextbooksQuery,
  useUploadContentTextbookMutation,
} from "../../generated/graphql";
import { BooksSearchBar } from "./components/Filters/BooksSearchBar";
import { useDebounce } from "../shared/Utils/OtherOperations/useDebounce";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  BooksHeadTableCell,
  BooksTableRow,
  BookTableCell,
  BookTableImage,
} from "./Books.style";
import { BOOK_TABLE_HEADER } from "./constants";
import { Menu } from "../shared/Menu";
import { SuccessSnackbar } from "../shared/SuccessSnackbar";

export const Books = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const debouncedSearchKey = useDebounce(searchKey, 500);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setPage(0);
  }, [searchKey]);

  const { data: booksData, loading: booksLoading } =
    useGetContentTextbooksQuery({
      variables: {
        data: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          search: debouncedSearchKey,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  const [importCsv, { loading: importCsvLoading }] =
    useUploadContentTextbookMutation();

  const [deleteBook, { loading: deleteBookLoading }] =
    useDeleteContentTextbookMutation();

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileChange = async (file: File) => {
    if (!file) return;
    try {
      await importCsv({
        variables: {
          csvFile: file,
        },
        refetchQueries: [GetContentTextbooksDocument],
      });
      setSuccessMessage("Файл успешно импортирован");
      setIsSuccessRequest(true);
    } catch (e) {
      setErrorMessage("При загрузке книжек произошла ошибка");
      setRequestError(true);
    }
  };

  const handleDeleteBook = async (id: string) => {
    try {
      await deleteBook({
        variables: {
          deleteContentTextbookId: id,
        },
        refetchQueries: [GetContentTextbooksDocument],
      });
      setSuccessMessage("Книга успешно удалена");
      setIsSuccessRequest(true);
    } catch (error) {
      setErrorMessage("При удаление книги произошла ошибка");
      setRequestError(true);
    }
  };

  const menuItem = (id: string) => [
    {
      name: "Удалить",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        handleDeleteBook(id);
      },
      color: "red",
    },
  ];

  const books = booksData?.getContentTextbooks?.textbooks ?? [];
  const total = booksData?.getContentTextbooks?.total ?? 0;

  const pagination = (
    <>
      {books.length > 0 ? (
        <CustomTablePagination
          rowsPerPageOptions={[10, 20]}
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );

  const booksTable = (
    <>
      {books.length > 0 ? (
        <Table>
          <thead>
            <BooksTableRow>
              {BOOK_TABLE_HEADER.map((el, i) => (
                <BooksHeadTableCell key={i}>{el}</BooksHeadTableCell>
              ))}
            </BooksTableRow>
          </thead>
          <tbody>
            {books.map(({ id, name, grade, year, image, author }) => (
              <BooksTableRow key={id}>
                <BookTableImage prim={image} />
                <BookTableCell>{name}</BookTableCell>
                <BookTableCell>{author}</BookTableCell>
                <BookTableCell>{grade}</BookTableCell>
                <BookTableCell>{year}</BookTableCell>
                <BookTableCell>
                  <Menu
                    vertical={36}
                    horizontal={12}
                    width={170}
                    items={menuItem(id)}
                  />
                </BookTableCell>
              </BooksTableRow>
            ))}
          </tbody>
        </Table>
      ) : null}
    </>
  );

  return (
    <Container maxWidth="lg">
      <Header>Книги</Header>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <BooksSearchBar
            label="Поиск..."
            activeFilter={searchKey}
            setActiveFilter={setSearchKey}
          />
        </Box>
        <Box>
          <ButtonCustom
            disabled={importCsvLoading}
            onClick={() => {
              fileInputRef.current.value = "";
              fileInputRef.current.click();
            }}
          >
            Импорт CSV файла
          </ButtonCustom>
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onFocus={() => {
              fileInputRef.current.value = "";
            }}
            onChange={({ target }) => handleFileChange(target.files[0])}
          />
        </Box>
      </Box>
      {pagination}

      {booksTable}

      {pagination}

      <SuccessSnackbar
        open={isSuccessRequest}
        handleClose={() => setIsSuccessRequest(false)}
        message={successMessage}
        snackbarProps={{
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
