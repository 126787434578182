import React from "react";
import { Box, Container } from "@material-ui/core";
import { ButtonPlus, Header } from "../shared/Style/Style";
import { useHistory } from "react-router-dom";
import { CollectionsTable } from "./components/CollectionsTable/CollectionsTable";

export const WhiteLabelCollections = () => {
  const { push } = useHistory();
  return (
    <Container maxWidth="lg">
      <Header>Коллекции</Header>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="centre"
        m="40px 0 20px"
      >
        <Box>
          <ButtonPlus
            width={172}
            variant="contained"
            onClick={() => push("/collections/create")}
          >
            Добавить
          </ButtonPlus>
        </Box>
      </Box>
      <CollectionsTable />
    </Container>
  );
};
