import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import { Payment } from "../../../../generated/graphql";
import { PaymentItemBlock, PaymentItemElement } from "./PaymentItem.styles";
import { TODAY_DATE, isExternalPayment } from "../utils";
import { CombinedPaymentTypeMap } from "../maps";

interface PaymentItemProps {
  payment: Payment;
  isPersonal: boolean;
  isHistory?: boolean;
}

export const formatDate = (date: string) =>
  format(new Date(date), "dd.MM.yyyy");
export const getDaysLeft = (endDate: string, startDate: string | Date) => {
  return differenceInDays(new Date(endDate), new Date(startDate));
};

const getPaymentStatus = (isActivated: boolean) => {
  return isActivated ? "Success" : "Pending";
};

export const PaymentItem = ({
  payment,
  isPersonal,
  isHistory = false,
}: PaymentItemProps) => {
  const {
    paymentDate,
    expiredDate,
    plan,
    paymentType,
    pupilsCount,
    teachersCount,
    total,
    isActivated,
    payer,
    validity,
  } = payment;

  const activePaymentContent = (
    <>
      <b>Подписка заканчивается через: </b>
      <span>{`${getDaysLeft(expiredDate, TODAY_DATE)}`}</span>
    </>
  );

  const historyPaymentContent = (
    <>
      <b>Тривалість підписки: </b>
      <span>{`${getDaysLeft(expiredDate, paymentDate)} днів`}</span>
    </>
  );

  const paymentStatusContent = (
    <>
      {isExternalPayment(paymentType) ? (
        <>
          <b>Статус: </b> <span>{getPaymentStatus(isActivated)}</span>
        </>
      ) : null}
    </>
  );

  const getSubscriptionDurationLabel = () => {
    const duration = isPersonal ? plan?.monthsCount : validity;

    if (duration === -1) {
      return "залежно від підписки простору";
    }

    return `${duration} міс.`;
  };

  return (
    <PaymentItemBlock>
      <PaymentItemElement>
        <b>Наданий доступ: </b> <span>{formatDate(paymentDate)}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Підписка доступна до: </b> <span>{formatDate(expiredDate)}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        {isHistory ? historyPaymentContent : activePaymentContent}
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тариф: </b> <span>{plan?.description || plan?.name}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Кількість місяців: </b> <span>{getSubscriptionDurationLabel()}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тип платежу: </b>{" "}
        <span>{CombinedPaymentTypeMap.get(paymentType) || paymentType}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        {!isPersonal && (
          <>
            <b>Кількість учнів: </b> <span>{pupilsCount}</span>
          </>
        )}
      </PaymentItemElement>
      <PaymentItemElement>
        {!isPersonal && (
          <>
            <b>Кількість вчителів: </b> <span>{teachersCount}</span>
          </>
        )}
      </PaymentItemElement>
      <PaymentItemElement>{paymentStatusContent}</PaymentItemElement>
      <PaymentItemElement>
        <b>Вартість: </b>{" "}
        <span>{`${total} ${
          process.env.REACT_APP_CURRENT_LOCALE === "en" ? "$" : "грн"
        }`}</span>
      </PaymentItemElement>
      {payer && (
        <PaymentItemElement>
          <b>Платник: </b> <span>{payer?.name}</span>
        </PaymentItemElement>
      )}
    </PaymentItemBlock>
  );
};
