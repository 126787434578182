import styled from "styled-components";
import {
  MainTableCell,
  MainTableRow,
  UserAvatarStyle,
} from "../../../shared/Style/Style";

export const CollectionsTableRow = styled(MainTableRow)`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 0.3fr;
  grid-gap: 14px;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;
`;

export const CollectionsTableCell = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: black;
`;

export const CollectionsHeadTableCell = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: black;
`;

export const CollectionTableCell = styled(MainTableCell)`
  font-size: 14px;
  line-height: 1.2;

  p {
    margin: 0;
  }
`;

export const CollectionTableImage = styled(UserAvatarStyle)`
  height: 88px;
  width: 88px;
`;
