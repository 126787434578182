import { useParams } from "react-router-dom";
import { useGetWhiteLabelCollectionFullQuery } from "../../../generated/graphql";
import { LoadingProgressOverlay } from "../../shared/LoadingProgress";
import {
  initialFieldsState,
  TextColorType,
} from "../components/WhiteLabelCollection/constants";
import { lazy, Suspense, useState } from "react";

const EditCollection = lazy(
  () => import("../components/WhiteLabelCollection/WhiteLabelCollection")
);

const EditCollectionLazy = () => {
  const { id } = useParams<{ id: string }>();
  const [collectionFields, setCollectionFields] = useState(initialFieldsState);

  const { loading } = useGetWhiteLabelCollectionFullQuery({
    variables: {
      getWhiteLabelCollectionId: id,
    },
    skip: !id,
    onCompleted: (data) => {
      setCollectionFields({
        name: {
          ...initialFieldsState.name,
          value: data.getWhiteLabelCollection?.name || "",
        },
        slug: {
          ...initialFieldsState.slug,
          value: data.getWhiteLabelCollection?.slug || "",
        },
        description: {
          ...initialFieldsState.description,
          value: data.getWhiteLabelCollection?.description || "",
        },
        accentColor: {
          ...initialFieldsState.accentColor,
          value: data.getWhiteLabelCollection?.backgroundColor || "",
        },
        textColor: {
          ...initialFieldsState.textColor,
          value: data.getWhiteLabelCollection?.textColor as TextColorType,
        },
        welcomeHtml: {
          ...initialFieldsState.welcomeHtml,
          value: data.getWhiteLabelCollection?.welcomeHtml || "",
        },
        schoolLogo: {
          ...initialFieldsState.schoolLogo,
          value: data.getWhiteLabelCollection?.schoolLogo || null,
        },
        schoolSystemLogoShort: {
          ...initialFieldsState.schoolSystemLogoShort,
          value: data.getWhiteLabelCollection?.systemLogo || null,
        },
        schoolSystemLogoFull: {
          ...initialFieldsState.schoolSystemLogoFull,
          value: data.getWhiteLabelCollection?.systemLogoLarge || null,
        },
        banner: {
          ...initialFieldsState.banner,
          value: data.getWhiteLabelCollection?.banner || null,
        },
        image: {
          ...initialFieldsState.image,
          value: data.getWhiteLabelCollection?.image || null,
        },
      });
    },
  });

  return (
    <Suspense fallback={<LoadingProgressOverlay />}>
      {loading && <LoadingProgressOverlay />}
      <EditCollection
        id={id}
        collectionFields={collectionFields}
        setCollectionFields={setCollectionFields}
      />
    </Suspense>
  );
};

export default EditCollectionLazy;
