import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import {
  ButtonCustom
} from "../../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../../shared/SuccessSnackbar";
import { DownloadQuotesCollection } from "./DownloadQuotesCollection";
import { DeleteQuotesCollection } from "./DeleteQuotesCollection";
import { ImportQuotesModal } from "./ImportQuotesModal";
import { Content, Text, Title, TitleQuotesContainer, SettingsItemsList, SettingsEmptyListPlaceholder, SettingsListElement } from "../Settings.style";
import { useGetQuoteCollectionsQuery } from "../../../../generated/graphql";

export type NotificationsType = {
    error?: boolean;
    success?: boolean;
    message: string;
};

export type ImportQuotesModalType = {
    id?: string;
    name?: string;
    open: boolean;
};

const initialImportQuotesModalData: ImportQuotesModalType = {
    id: undefined,
    name: undefined,
    open: false,
};

const initialNotifications: NotificationsType = {
  error: false,
  success: false,
  message: "",
}

export const QuotesCollection = () => {
    const [openImportQuotesModal, setOpenImportQuotesModal] = useState(initialImportQuotesModalData);
    const [notifications, setNotifications] = useState(initialNotifications);

    const { data: dataQuotes } = useGetQuoteCollectionsQuery(
        {
            onError: () => {
                setNotifications({ error: true, message: "При завантаженні колекцій цитат виникла помилка." })
            }, 
        }
    );

    const quotes = dataQuotes?.getQuoteCollections || [];
    

    return (
        <>
            <Content>
                <TitleQuotesContainer>
                    <Title>
                        <Text>Колекція цитат</Text>
                        <ButtonCustom
                            isActive
                            onClick={() => setOpenImportQuotesModal({ ...openImportQuotesModal, open: true })}
                        >
                            Додати
                        </ButtonCustom>
                    </Title>
                </TitleQuotesContainer>
                {quotes.length > 0 ? (
                    <SettingsItemsList>
                    {quotes.map(quote => (
                        <SettingsListElement key={quote.id} button={true}>
                            <Typography align="center" style={{ minWidth: "220px" }}>{quote.name}</Typography>
                            <div>
                                <DownloadQuotesCollection
                                    collectionId={quote.id}
                                    collectionName={quote.name}
                                    setNotifications={setNotifications}
                                />
                                <Tooltip title="Оновити колекцію цитат">
                                    <IconButton onClick={() => setOpenImportQuotesModal({ id: quote.id, name: quote.name , open: true })}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <DeleteQuotesCollection id={quote.id} setNotifications={setNotifications} />
                            </div>
                        </SettingsListElement>
                    ))}
                    </SettingsItemsList>
                ) : (
                    <SettingsItemsList>
                        <SettingsEmptyListPlaceholder>
                            Натисніть кнопку додати, щоб додати колекцію цитат.
                        </SettingsEmptyListPlaceholder>
                    </SettingsItemsList>
                )}
            </Content>

            {openImportQuotesModal.open && (
                <ImportQuotesModal
                    quotesId={openImportQuotesModal.id}
                    quotesName={openImportQuotesModal.name}
                    closeModal={() => setOpenImportQuotesModal(initialImportQuotesModalData)}
                    setNotifications={setNotifications}
                />
            )}

            {notifications.error && (
                <ErrorMessageSnackbar
                    open={notifications.error}
                    closeHandler={() => setNotifications(initialNotifications)}
                    message={notifications.message}
                />
            )}

            {notifications.success && (
                <SuccessSnackbar
                    open={notifications.success}
                    handleClose={() => setNotifications(initialNotifications)}
                    message={notifications.message}
                    snackbarProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                />
            )}
        </>
    );
};
