import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  AddTeacherToSchoolDocument,
  AddTeacherToSchoolMutation,
  GetSchoolDocument,
  GetSchoolQuery,
  RemoveTeacherFromSchoolDocument,
  RemoveTeacherFromSchoolMutation,
  SchoolUserStatusEnum,
  UpdateSchoolDocument,
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables,
  UserRoleEnum,
} from "../../generated/graphql";
import { School } from "./School";
import { useRequestError } from "../../hooks";
import { useSchoolRedirect } from "./hooks";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { LoadingProgress } from "../shared/LoadingProgress";
import { SchoolPaths } from "../shared/constants";
import { getAdminIdsToUpdate, mapUsersIds } from "./utils";
import { Container } from "@material-ui/core";
import { BasicTabs } from "../CustomTabPanel";
import { Header } from "../shared/Style/Style";
import { Purchases } from "./Purchases";
import { ParticipantsList } from "./ParticipantsList";
import { Analytics } from "./Analytics/Analytics";
import { UserInfoContext } from "../Main";
import { UserFeatureFlags } from "./UserFeatureFlags";
import { SchoolPupilsSubscriptions } from "./SchoolPupilsSubscriptions/SchoolPupilsSubscriptions";

export interface TabList {
  tabName: string;
  TabPanel: JSX.Element;
  disabled?: boolean;
  tooltipText?: string;
}

export const UpdateSchool = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const handleBackButtonEvent = (e: PopStateEvent) => {
      e.preventDefault();
      window.history.back();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", handleBackButtonEvent);
    };
  }, []);

  const {
    data: getSchoolData,
    loading: getSchoolLoading,
    error: getSchoolError,
  } = useQuery<GetSchoolQuery>(GetSchoolDocument, {
    variables: { id: schoolId },
    fetchPolicy: "network-only",
  });

  const refetchQuery = {
    query: GetSchoolDocument,
    variables: { id: schoolId },
  };

  const [
    updateSchool,
    { loading: updateSchoolLoading, error: updateSchoolError },
  ] = useMutation<UpdateSchoolMutation>(UpdateSchoolDocument, {
    refetchQueries: [refetchQuery],
  });

  const [
    addTeacherToSchool,
    { loading: addTeacherToSchoolLoading, error: addTeacherToSchoolError },
  ] = useMutation<AddTeacherToSchoolMutation>(AddTeacherToSchoolDocument, {
    refetchQueries: [refetchQuery],
  });

  const [
    removeTeacherFromSchool,
    {
      loading: removeTeacherFromSchoolLoading,
      error: removeTeacherFromSchoolError,
    },
  ] = useMutation<RemoveTeacherFromSchoolMutation>(
    RemoveTeacherFromSchoolDocument,
    {
      refetchQueries: [refetchQuery],
    }
  );

  const error =
    getSchoolError ||
    updateSchoolError ||
    addTeacherToSchoolError ||
    removeTeacherFromSchoolError;
  const { requestError, setRequestError } = useRequestError(error);

  const disabledOnLoading =
    updateSchoolLoading ||
    getSchoolLoading ||
    addTeacherToSchoolLoading ||
    removeTeacherFromSchoolLoading;

  const isUserCanManagePermissions = useContext(
    UserInfoContext
  )?.userPermissions?.includes("CAN_MANAGE_PERMISSIONS");

  const school = getSchoolData?.getSchool;
  const isHasSchoolSubscription =
    !school?.subscriptionInfo?.plan?.name.includes("BASE");

  const errorMessage =
    (getSchoolError && "При загрузке данных школы произошла ошибка") ||
    (updateSchoolError && "При обновлении данных школы произошла ошибка") ||
    (addTeacherToSchoolError &&
      "При добавлении администратора школы произошла ошибка") ||
    (removeTeacherFromSchoolError &&
      "При удалении администратора школы произошла ошибка");

  const redirect = useSchoolRedirect(school?.isPersonal);

  const schoolAdminsIdsBeforeUpdate = [...mapUsersIds(school?.admins)];

  const handleSchoolUpdate = useCallback(
    async (variables: UpdateSchoolMutationVariables) => {
      try {
        await updateSchool({ variables });
      } catch (e) {
        console.error(e);
      }
    },
    [updateSchool]
  );

  const handleAdminsUpdate = useCallback(
    async (adminsIds: string[]) => {
      const { adminsToAdd, adminsToDelete } = getAdminIdsToUpdate(
        adminsIds,
        schoolAdminsIdsBeforeUpdate
      );

      try {
        if (adminsToAdd.length > 0) {
          for (const teacherId of adminsToAdd) {
            await addTeacherToSchool({
              variables: {
                schoolId,
                teacherId,
                teacherStatus: SchoolUserStatusEnum.Admin,
              },
            });
          }
        }

        if (adminsToDelete.length > 0) {
          for (const teacherId of adminsToDelete) {
            await removeTeacherFromSchool({
              variables: { schoolId, teacherId },
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      addTeacherToSchool,
      removeTeacherFromSchool,
      schoolAdminsIdsBeforeUpdate,
      schoolId,
    ]
  );

  const tabHeaders = [
    "Редагування школи",
    "Товари школи",
    "Аналітика школи",
    "Учасники школи",
  ];

  if (isUserCanManagePermissions) {
    if (school?.isPersonal) {
      tabHeaders.push("Керування дозволами користувача");
    }
    tabHeaders.push("Оплата підписки");
  }

  const tabList = useMemo(() => {
    const tabs: TabList[] = [
      {
        tabName: "РЕДАГУВАННЯ",
        TabPanel: (
          <School
            disable={disabledOnLoading}
            updateSchool={handleSchoolUpdate}
            updateAdmins={handleAdminsUpdate}
            schoolDataUpdate={school}
          />
        ),
      },
      {
        tabName: "ТОВАРИ",
        TabPanel: <Purchases />,
      },
      {
        tabName: "АНАЛІТИКА",
        TabPanel: <Analytics />,
      },
      {
        tabName: "УЧАСНИКИ",
        TabPanel: <ParticipantsList />,
      },
    ];

    if (isUserCanManagePermissions) {
      if (school?.isPersonal) {
        tabs.push({
          tabName: "КЕРУВАННЯ ДОЗВОЛАМИ",
          TabPanel: <UserFeatureFlags schoolId={schoolId} />,
        });
      }
      if (
        !school?.isPersonal ||
        ![UserRoleEnum.Pupil, UserRoleEnum.Parent].includes(
          school?.createdBy?.role?.role
        )
      ) {
        tabs.push({
          tabName: "ОПЛАТА ПІДПИСКИ",
          TabPanel: (
            <SchoolPupilsSubscriptions
              disable={disabledOnLoading}
              schoolDataUpdate={school}
            />
          ),
          disabled: !isHasSchoolSubscription,
          tooltipText:
            "Активувати підписку учням можна лише за наявності активної підписки у школи/вчителя",
        });
      }
    }

    return tabs;
  }, [
    disabledOnLoading,
    handleSchoolUpdate,
    handleAdminsUpdate,
    school,
    isUserCanManagePermissions,
    isHasSchoolSubscription,
    schoolId,
  ]);

  if (redirect.toPersonal) {
    return <Redirect to={`/${SchoolPaths.personal}/${schoolId}/edit`} />;
  }

  if (redirect.toSchool) {
    return <Redirect to={`/${SchoolPaths.school}/${schoolId}/edit`} />;
  }

  if (disabledOnLoading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Container>
        <Header>{tabHeaders[activeTab]}</Header>
        <BasicTabs
          tabList={tabList}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
      </Container>
      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
